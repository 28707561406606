import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';
import { put, takeLatest } from 'redux-saga/effects';

export const projectCreditsSlice = createAppTableStore({
  name: 'tableProjectCredits' as const,
  url: '',
  initialState: {
    params: {
      sort: { column: 'date', order: 'descend' },
    },
    totalTime: null as null | number,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: number; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(projectCreditsSlice.actions.setTotalTime(Number(payload.total_time)));
}

export const projectCreditsSaga = createAppTableSaga(projectCreditsSlice, function* customGenerator() {
  yield takeLatest(projectCreditsSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const projectCreditsReducer = projectCreditsSlice.reducer;
