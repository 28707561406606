/* eslint-disable max-len */
import { CommentTemplate } from 'types';

const templates: CommentTemplate[] = [
  {
    uuid: '1',
    title: 'When the estimate is ready (initial send)',
    content:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);"><span class="mention" data-index="1" data-denotation-char="@" data-value="name" data-id=""><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>name</span></span> </span><span style="background-color: transparent; color: rgb(67, 67, 67);">- </span><span style="background-color: transparent; color: rgb(0, 0, 0);">we\'ve reviewed your brief and put together a summary of the project scope. Please take a look and let me know if you would like our team to get started.</span></p><p><br></p><p><strong style="background-color: transparent; color: rgb(0, 0, 0);">Deliverables &amp; Specs</strong></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">[item - ex: Instagram]</span></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">- [detail - ex: 2-3 post]</span></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">- [detail - ex: 1080x1920 pixels with a 9:16 aspect ratio]</span></p><p><br></p><p><strong style="background-color: transparent; color: rgb(0, 0, 0);">Estimate:</strong></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">[X-X] hours</span></p><p><br></p><p><strong style="background-color: transparent; color: rgb(0, 0, 0);">Note:</strong><span style="background-color: transparent; color: rgb(0, 0, 0);"> This estimate includes design, creative direction, project management time, and two rounds of revisions.</span></p>',
  },
  {
    uuid: '2',
    title: 'Follow-up on estimate approval',
    content:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);"><span class="mention" data-index="1" data-denotation-char="@" data-value="name" data-id=""><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>name</span></span> - we’re excited to begin, but we need your approval on the hours before we start.</span></p>',
  },
  {
    uuid: '3',
    title: 'Once the project/request is completed',
    content:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);"><span class="mention" data-index="1" data-denotation-char="@" data-value="name" data-id=""><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>name</span></span> - your project is done! Check it out and let me know what you think. Once you approve, we’ll close this request. Don’t worry—you can always find completed tasks by adjusting the filter on your dashboard.</span></p>',
  },
  {
    uuid: '4',
    title: 'Follow-up on final approval',
    content:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);"><span class="mention" data-index="1" data-denotation-char="@" data-value="name" data-id=""><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>name</span></span> - did you have any final feedback or can we complete this? You can always find completed tasks by adjusting the filter on your dashboard.</span></p>',
  },
  {
    uuid: '5',
    title: 'Need extra info/clarifications',
    content:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);"><span class="mention" data-index="1" data-denotation-char="@" data-value="name" data-id=""><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>name</span></span> - quick question—could you clarify [X] for us so we can continue?”</span></p>',
  },
  {
    uuid: '6',
    title: 'If scope changes / discovery mid-project',
    content:
      '<p><span style="background-color: transparent; color: rgb(0, 0, 0);"><span class="mention" data-index="1" data-denotation-char="@" data-value="name" data-id=""><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>name</span></span> - we found a new opportunity for [X]. Do you have time this week for a quick chat?</span></p>',
  },
];

export default templates;
