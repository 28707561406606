import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FiUser } from 'react-icons/fi';
import { Form, FormInstance } from 'antd';
import SelectUserAvatar from 'components/SelectUserAvatar';
import { RootState } from 'store';
import { UserResponse } from 'types';

interface SelectResponsibleProps {
  state: any;
  label?: ReactNode;
  form: FormInstance;
  setState: any;
  finalProjectId: any;
  dropdownMatchSelectWidth?: boolean | number;
  onlyAvatar?: boolean;
  bordered?: boolean;
  disabled?: boolean;
}

const SelectResponsible: FC<SelectResponsibleProps> = ({
  state,
  label,
  form,
  setState,
  finalProjectId,
  dropdownMatchSelectWidth,
  onlyAvatar,
  bordered,
  disabled,
}) => {
  const user = useSelector((store: RootState) => store.auth.user) as UserResponse;

  const stateResponsible = state?.responsibles?.[0];

  const defaultResponsibleOptions = [
    { value: user.uuid, label: user.name, user },
    ...(stateResponsible && stateResponsible.uuid !== user.uuid
      ? [
          {
            value: stateResponsible.uuid,
            label: stateResponsible.name,
            user: stateResponsible,
          },
        ]
      : []),
  ];

  return (
    <Form.Item
      label={
        label ?? (
          <>
            <FiUser />
            Assignee
          </>
        )
      }
      name="responsibles"
      style={{ marginBottom: 0 }}
      rules={[
        ...(state?.recurrence
          ? [
              {
                required: true,
                message: 'This field is required',
              },
            ]
          : []),
      ]}
    >
      <SelectUserAvatar
        placeholder="Select a user"
        size="middle"
        projectId={finalProjectId}
        defaultOptions={defaultResponsibleOptions}
        filterType={['regular_user', 'freelancer']}
        bordered={bordered}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        disabled={disabled}
        onlyAvatar={onlyAvatar}
        onChange={(_, option) => {
          setState((prevState: any) => ({ ...prevState, responsibles: option ? [option.user] : [] }));

          const newFollowers = option ? state?.followers?.filter((x: any) => x.uuid !== option.value) : state?.followers;

          if (newFollowers && newFollowers.length !== state.followers.length) {
            const ids = newFollowers.map((x: any) => x.uuid);

            setState((prevState: any) => ({ ...prevState, followers: newFollowers }));

            form.setFieldsValue({ followers: ids });
          }
        }}
      />
    </Form.Item>
  );
};

export default SelectResponsible;
