import * as Sentry from '@sentry/react';
import ENVIRONMENT from 'config/environment';

Sentry.init({
  dsn: ENVIRONMENT.REACT_APP_SENTRY_DNS,
  environment: ENVIRONMENT.REACT_APP_SENTRY_ENVIRONMENT,
  release: ENVIRONMENT.REACT_APP_SENTRY_RELEASE,
  ignoreErrors: [
    // Last pass error, on safari https://github.com/WordPress/openverse/issues/524
    'Non-Error promise rejection captured with value: Not implemented on this platform',
    // Last pass error
    'ResizeObserver loop completed with undelivered notifications.',
    'timeout exceeded',
    'Network Error',
    'AxiosError',
  ],
  denyUrls: [
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^webkit-masked-url:\/\/hidden/i,
    /^file:\/\//i,
    /.*\/node_modules\/lib0\/websocket\.js/i,
    /www\.clarity\.ms\/s\/.*\/clarity\.js/i,
  ],
  beforeSend(event) {
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id });
    // }
    return event;
  },
});

export function setSentryUser(user: any) {
  Sentry.setUser(
    user?.uuid
      ? {
          id: user.uuid,
          name: user.name,
          email: user.email,
        }
      : null,
  );
}
