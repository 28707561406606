import { CSSProperties, FC, ReactNode } from 'react';
import { DownloadOutlined, FileImageOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Skeleton, Space, Tag, Typography } from 'antd';
import StatusTag from 'components/StatusTag';
import DownloadAsset from 'components/UploadsList/components/DownloadAsset';
import UserAvatar from 'components/UserAvatar';
import { UploadResponse } from 'types/upload-type';
import { convertMinsToHrsMins, convertSnakeCaseToTitleCase, parseDateToUSFormat } from 'utils/string.utils';
import useActivityLogs, { ActivityLogModelType } from './useActivityLogs';
import UpdatedListLog from './UpdatedListLog';
import UpdatedTextLog from './UpdatedTextLog';
import RemovedTextLog from './RemovedTextLog';

interface ActivityLogsProps {
  model: Record<string, any> | null;
  modelType: ActivityLogModelType;
  style?: CSSProperties;
}

const { Title, Text } = Typography;

const mapIgnoreFields = ['related_users', 'editor', 'completed_at', 'updated_at'];

const mapChangeRenderText: Record<string, (change: any) => ReactNode> = {
  description: () => undefined,
  description_delta: () => undefined,
  time_spent: (change) => convertMinsToHrsMins(change.added),
  estimated_time: (change) => convertMinsToHrsMins(change.added),
  estimated_time_min: (change) => convertMinsToHrsMins(change.added),
  estimated_min: (change) => convertMinsToHrsMins(change.added),
  estimated_max: (change) => convertMinsToHrsMins(change.added),
  start_date: (change) => parseDateToUSFormat(change.added),
  end_date: (change) => parseDateToUSFormat(change.added),
  created_at: (change) => parseDateToUSFormat(change.added),
  approval_target: (change) => parseDateToUSFormat(change.added),
  target_date: (change) => parseDateToUSFormat(change.added, false, true),
  category: (change) => change.name,
  status: (change) => <StatusTag value={change.added} />,
  project: (change) => change.added.title ?? change.added.customer,
  parent: (change) => change.added.title,
  priority: (change) => {
    const priorities = [
      { label: 'low', value: 0 },
      { label: 'medium', value: 1, color: 'yellow' },
      { label: 'high', value: 2, color: 'red' },
    ];

    return <Tag color={priorities[change.added].color}>{convertSnakeCaseToTitleCase(priorities[change.added].label, false)}</Tag>;
  },
};

const mapChangeRenderList: Record<
  string,
  { addedPrefix?: string; renderContainer?: (list: any[]) => ReactNode; render?: (item: any) => string }
> = {
  assignees: { render: (item) => item.name },
  followers: { render: (item) => item.name },
  tags: { render: (item) => item.name },
  requests: { addedPrefix: 'Related', render: (item) => `${item.company_name}(${parseDateToUSFormat(item.created_at, true)})` },
  uploads: {
    renderContainer: (list) => {
      return (
        <Row gutter={[8, 8]}>
          {list.map((el: UploadResponse) => {
            return (
              <Col span={12} key={el.uuid}>
                <Row
                  wrap={false}
                  justify="space-between"
                  align="middle"
                  style={{
                    padding: '4px 8px',
                    background: '#F0F0F0',
                    borderRadius: 4,
                  }}
                >
                  <Col lg={{ span: 21 }} span={20}>
                    <Text ellipsis style={{ fontWeight: '200 !important' }}>
                      <FileImageOutlined />
                      &nbsp;
                      {el.name}
                    </Text>
                  </Col>

                  <Col>
                    <DownloadAsset uuid={el.uuid} name={el.name} icon={<DownloadOutlined />} />
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      );
    },
  },
};

const ActivityLogs: FC<ActivityLogsProps> = ({ model, modelType, style }) => {
  const { state, fetchData } = useActivityLogs(model?.uuid, modelType);
  const { data, loading, hasMore } = state;

  if (loading && data.length === 0) {
    return (
      <Card className="mb-l">
        <Skeleton />
      </Card>
    );
  }

  return (
    <Card className="mb-l" style={style}>
      {data.length > 0 && (
        <Space direction="vertical" className="w-full mb-l" size={20}>
          {data.map((current: Record<string, any>) => {
            const isCreated = current.action === 'create';
            const changesList = Object.values(current.changes);

            const renderedFields: string[] = [];

            if (isCreated && changesList.length <= 1) {
              return null;
            }

            return (
              <Space key={current.id} align="start">
                <UserAvatar user={current.author} />

                <div style={{ paddingTop: 5 }}>
                  <Space size={12} className="mb-s">
                    <Title className="mb-0" level={5}>
                      {current.author.name}
                    </Title>

                    <Text className="disabled text-sm">{parseDateToUSFormat(current.created_at, true)}</Text>
                  </Space>

                  <Space size={5} className="w-full" direction="vertical">
                    {changesList.map((changes: any, changesIndex) => {
                      if (isCreated && changesIndex === changesList.length - 1) {
                        return null;
                      }

                      return Object.keys(changes).map((changeKey, index) => {
                        let title = changeKey === 'description_delta' ? 'description' : changeKey;

                        if (modelType === 'request' && title === 'description') {
                          title = 'form';
                        }

                        if (mapIgnoreFields.includes(title) || renderedFields.includes(title)) {
                          return null;
                        }

                        const { added, removed } = changes[changeKey];

                        const hasAdded = (Array.isArray(added) && added.length > 0) || (!Array.isArray(added) && !!added);

                        const hasRemoved =
                          (Array.isArray(removed) && removed.length > 0) || (!Array.isArray(removed) && !!removed);

                        if (!hasAdded && !hasRemoved) {
                          return <div key={index}>EMPTY</div>;
                        }

                        if (Array.isArray(added)) {
                          const listProps = changeKey in mapChangeRenderList ? mapChangeRenderList[changeKey] : undefined;

                          return (
                            <UpdatedListLog
                              key={index}
                              title={title}
                              change={changes[changeKey]}
                              addedPrefix={listProps?.addedPrefix}
                              render={listProps?.render}
                              renderContainer={listProps?.renderContainer}
                            />
                          );
                        }

                        renderedFields.push(changeKey);

                        if (added === null || added === undefined) {
                          return <RemovedTextLog key={index} title={title} />;
                        }

                        let textValue =
                          changeKey in mapChangeRenderText ? mapChangeRenderText[changeKey](changes[changeKey]) : undefined;
                        textValue = !(changeKey in mapChangeRenderText) && typeof added === 'object' ? '[OBJECT]' : textValue;
                        textValue = !(changeKey in mapChangeRenderText) && typeof added !== 'object' ? added : textValue;

                        return (
                          <UpdatedTextLog key={index} title={title}>
                            {textValue}
                          </UpdatedTextLog>
                        );
                      });
                    })}
                  </Space>
                </div>
              </Space>
            );
          })}
        </Space>
      )}

      {hasMore && (
        <Divider style={{ marginBottom: 10, marginTop: '-10px' }}>
          {loading ? (
            <Text className="primary">Loading...</Text>
          ) : (
            <Text onClick={() => model && fetchData(model.uuid)} className="primary pointer">
              Load more
            </Text>
          )}
        </Divider>
      )}

      <Space align="start">
        <UserAvatar user={model?.creator} />

        <div style={{ paddingTop: 5 }}>
          <Space size={12} className="mb-s">
            <Title className="mb-0" level={5}>
              {model?.creator?.name}
            </Title>

            <Text className="disabled text-sm">{parseDateToUSFormat(model?.created_at, true)}</Text>
          </Space>

          <div>
            <Text>
              <span style={{ textTransform: 'capitalize' }}>{modelType}</span> created
            </Text>
          </div>
        </div>
      </Space>
    </Card>
  );
};

export default ActivityLogs;
