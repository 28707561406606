import { FC } from 'react';
import { Card, Tooltip } from 'antd';
import { CommentTemplate } from '../../types';
import RichTextEditor from '../RichTextEditor';

interface TemplateCommentCardProps {
  template: CommentTemplate;
  onClick?: (template: CommentTemplate) => void;
}

const CardTooltip = ({ template }: { template: CommentTemplate }) => {
  return (
    <Card title={template.title} size="small" bodyStyle={{ overflowY: 'auto', maxHeight: 300 }}>
      <RichTextEditor className="comment-styles" initialValue={template.content} readonly noContainer />
    </Card>
  );
};

const TemplateCommentCard: FC<TemplateCommentCardProps> = ({ template, onClick }) => {
  return (
    <Tooltip
      title={<CardTooltip template={template} />}
      overlayInnerStyle={{ padding: 0, background: 'transparent' }}
      destroyTooltipOnHide
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          background: '#DFEFFF',
          padding: '0px 8px',
          border: '1px solid rgba(0, 68, 204, 0.2)',
          cursor: 'pointer',
          borderRadius: 4,
          lineHeight: 1.2,
          textAlign: 'center',
        }}
        key={template.uuid}
        onClick={() => onClick?.(template)}
      >
        {template.uuid}
      </div>
    </Tooltip>
  );
};

export default TemplateCommentCard;
