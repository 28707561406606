const allStatus = [
  { label: 'Todo', value: 'todo' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Waiting approval', value: 'waiting_approval' },
  { label: 'Working On Now', value: 'working_on_now' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Needs attention', value: 'needs_attention' },
];

const findStatus = (status: string) => {
  return (
    allStatus.find((item) => item.value === status) ?? {
      label: status,
      value: status,
    }
  );
};

const getTaskStatusOptions = ({ responsible, status }: { responsible?: any; status?: any }) => {
  if (responsible?.find((el: any) => el.type === 'contact')) {
    const list = [
      { label: 'Waiting approval', value: 'waiting_approval' },
      { label: 'Pending', value: 'pending' },
      { label: 'Completed', value: 'completed' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Needs attention', value: 'needs_attention' },
    ];

    if (list.find((x) => x.value === status)) {
      return list;
    }

    return [findStatus(status), ...list];
  }

  return allStatus;
};

export default getTaskStatusOptions;
