import { AxiosResponse } from 'axios';
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';
import apiRoutes from 'config/apiRoute';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { RootState } from 'store';
import { getProjectSelect, setProjectSelectData, setProjectSelectError, setProjectSelectLoading } from './projectSelect-slice';

const { get } = api;

function* getProjectSelectSagaListener() {
  const loading: boolean = yield select((store: RootState) => store.projectSelect.loading);

  if (loading) return;

  yield put(setProjectSelectLoading());

  try {
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECTS_LITE, {
      'page[size]': 999,
      with_personal: true,
    });

    yield put(setProjectSelectData(res.data));
  } catch (error) {
    yield put(setProjectSelectError(error));
    asyncErrorHandler(error);
  }
}

function* projectSelectSaga(): Generator<StrictEffect> {
  yield takeEvery(getProjectSelect.type, getProjectSelectSagaListener);
}

export default projectSelectSaga;
