import { FC } from 'react';
import { Select, SelectProps } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useProjectSelect from './useProjectSelect';

interface ProjectSelectProps {
  value?: string;
  className?: string;
  size?: SizeType;
  initialProjects?: Record<string, any>[];
  allowClear?: boolean;
  withPersonal?: boolean;
  onlyRetainer?: boolean;
  onlyActive?: boolean;
  bordered?: boolean;
  multiple?: boolean;
  onChange?: SelectProps<string>['onChange'];
}

const ProjectSelect: FC<ProjectSelectProps> = ({
  value,
  className,
  size,
  allowClear,
  withPersonal,
  onlyRetainer,
  onlyActive,
  bordered,
  multiple,
  initialProjects,
  onChange,
}) => {
  const { data, loading } = useSelector((store: RootState) => store.projectSelect);

  const projects = data?.data ?? initialProjects;

  const { options, onDropdownVisibleChangeHandler, filterOption } = useProjectSelect({
    projects,
    withPersonal,
    onlyRetainer,
    onlyActive,
  });

  return (
    <Select
      value={value}
      className={className}
      dropdownClassName="no-padding"
      placeholder="Select a project"
      optionLabelProp="display"
      mode={multiple ? 'multiple' : undefined}
      maxTagCount="responsive"
      menuItemSelectedIcon={null}
      options={options}
      loading={loading}
      size={size}
      allowClear={allowClear}
      bordered={bordered}
      autoFocus
      showSearch
      filterOption={filterOption}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChangeHandler}
    />
  );
};

export default ProjectSelect;
