import { FC, ReactNode } from 'react';
import { Space, Typography } from 'antd';
import { convertArrToCommaSeparatedString } from 'utils/string.utils';

const { Text } = Typography;

interface UpdatedListLogProps {
  title: string;
  change: any;
  addedPrefix?: string;
  render?: (item: any) => string;
  renderContainer?: (list: any) => ReactNode;
}

const UpdatedListLog: FC<UpdatedListLogProps> = ({ title, change, addedPrefix = 'Added', renderContainer, render }) => {
  return (
    <Space size={5} className="w-full mb-0" direction="vertical">
      {change.added.length > 0 && (
        <Text>
          {addedPrefix} {title}:{' '}
          {renderContainer ? (
            renderContainer(change.added)
          ) : (
            <Text strong>{convertArrToCommaSeparatedString(change.added.map((el: any) => render?.(el) ?? undefined))}</Text>
          )}
        </Text>
      )}
      {change.removed?.length > 0 && (
        <Text>
          Removed {title}:{' '}
          {renderContainer ? (
            renderContainer(change.removed)
          ) : (
            <Text strong>{convertArrToCommaSeparatedString(change.removed.map((el: any) => render?.(el) ?? undefined))}</Text>
          )}
        </Text>
      )}
    </Space>
  );
};

export default UpdatedListLog;
