import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import TaskModal from 'components/TaskModal';
import { RootState } from 'store';
import {
  setGlobalTaskModalClosedTrigger,
  setGlobalTaskModalRemovedRequestTrigger,
  setGlobalTaskModalSavedTrigger,
  setGlobalTaskModalUpdatedSubtaskTrigger,
} from 'store/container/globalTaskModal/globalTaskModal-slice';
import useUrlSearchParams from 'utils/useUrlSearchParams';
import appRoutes from 'config/appRoutes';

const GlobalTaskModal = () => {
  const taskProject = useSelector((store: RootState) => store.globalTaskModal.project);

  const history = useHistory();

  const dispatch = useDispatch();

  const search = useUrlSearchParams();

  const taskAnyProject = search.has('task_any_project');

  const requestPage =
    matchPath(history.location.pathname, {
      path: `${appRoutes.REQUESTS}/:uuid`,
      exact: true,
    }) !== null;

  const projectRequestPage =
    matchPath<any>(history.location.pathname, {
      path: `${appRoutes.PROJECTS}/:uuid/:tab/:uuidModel`,
      exact: true,
    })?.params?.tab === 'requests';

  return (
    <TaskModal
      visible={search.get('task_uuid')}
      mask={taskAnyProject || (!requestPage && !projectRequestPage)}
      project={taskAnyProject ? undefined : taskProject}
      highlightCommentUuid={search.get('task_comment_uuid') ?? undefined}
      createToParentUuid={search.get('task_parent_id') ?? undefined}
      createToStatus={search.get('task_status') ?? undefined}
      createFromTaskUuid={search.get('task_copy_uuid') ?? undefined}
      isPersonal={search.has('task_personal')}
      onSaved={() => dispatch(setGlobalTaskModalSavedTrigger())}
      onRemoveRequest={() => dispatch(setGlobalTaskModalRemovedRequestTrigger())}
      onUpdateSubtask={() => dispatch(setGlobalTaskModalUpdatedSubtaskTrigger())}
      onClose={() => {
        history.push(history.location.pathname);
        dispatch(setGlobalTaskModalClosedTrigger());
      }}
    />
  );
};

export default GlobalTaskModal;
