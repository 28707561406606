import ENVIRONMENT from './environment';

const { BASE_URL } = ENVIRONMENT;
const BASE_URL_PLAIN = BASE_URL?.substring(0, BASE_URL.length - 4);

const apiRoutes = {
  BASE_URL,
  BASE_URL_PLAIN,
  // Auth
  USERS: `${BASE_URL}/auth/users`,
  PROJECT_USERS: `${BASE_URL}/projects/users`,
  USER_INFO: `${BASE_URL}/auth/user`,
  LOGIN: `${BASE_URL}/auth/login`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  CONTACT_REGISTER: `${BASE_URL}/auth/contact-register`,
  RESET_EMAIL: `${BASE_URL}/auth/password/email`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
  GLOBAL_SEARCH: `${BASE_URL}/global-search`,

  // Modules
  ROLES: `${BASE_URL}/acl/roles`,
  PERMISSIONS: `${BASE_URL}/acl/permissions`,
  GROUPS: `${BASE_URL}/groups`,

  // Logs
  EMAIL_LOGS: `${BASE_URL}/logs/sent-emails`,

  // onboarding
  ONBOARDING: `${BASE_URL}/billing/onboarding`,
  ONBOARDING_STEPS: `${BASE_URL}/billing/onboarding-steps`,
  ONBOARDING_INSTRUCTIONS: 'https://cms.agencysavage.com/onboard-instructions',
  ONBOARDING_CLIENT: `${BASE_URL}/customer/onboarding`,

  // AIR TABLE
  ADS_PROJECTS: `${BASE_URL}/airtable/advertising-projects`,
  NOT_ADS_PROJECTS: `${BASE_URL}/airtable/not-advertising-projects`,

  RESEND_INVITE_DROPBOX: `${BASE_URL}/resend-invite/dropbox`,
  RESEND_INVITE_WRIKE: `${BASE_URL}/resend-invite/wrike`,
  RESEND_INVITE_TYPEFORM: `${BASE_URL}/resend-invite/type-form`,

  // ANALYTICS
  ANALYTICS_GOOGLE: `${BASE_URL}/google-analytics`,
  ANALYTICS_FB: `${BASE_URL}/facebook-analytics`,

  DASHBOARD: `${BASE_URL}/admin/dashboard`,
  DASHBOARD_COUNTER: `${BASE_URL}/dashboard-counters`,
  ADMINISTRATION: `${BASE_URL}/billing/invoice-settings`,

  // eslint-disable-next-line max-len
  PROJECT_LOG: `${BASE_URL}/comments-report?filters[commentable_type][]=projects&sort[by]=created_at&sort[direction]=desc`,
  RECENT_PROJECT_LOG: `${BASE_URL}/recent-project-logs`,
  ACTIVITY_LOG: `${BASE_URL}/activity-logs`,

  // CUSTOMERS
  CUSTOMERS: `${BASE_URL}/billing/customers`,
  CUSTOMER_INVITE: `${BASE_URL}/billing/customer-invitations`,
  STATS: `${BASE_URL}/billing/customer-stats`,

  // DISCOUNTS
  DISCOUNTS: `${BASE_URL}/billing/discount-sources`,

  // REQUESTS
  REQUESTS: `${BASE_URL}/projects/requests`,

  // CONTACTS
  CONTACTS: `${BASE_URL}/billing/contacts`,
  ASSIGNEES: `${BASE_URL}/projects/assignees`,

  // RESOURCES
  RESOURCES: 'https://cms.agencysavage.com/resources/18',

  // PROJECTS
  COMMENTS: `${BASE_URL}/comments`,
  REACTION: `${BASE_URL}/reaction`,
  INBOX: `${BASE_URL}/mentions/inbox`,
  MENTIONS: `${BASE_URL}/mentions`,
  MENTIONS_SENT: `${BASE_URL}/mentions/sent`,
  PROJECTS: `${BASE_URL}/projects/projects`,
  PROJECT_TIME_REPORT: `${BASE_URL}/projects/projects-time-report`,
  PROJECTS_LITE: `${BASE_URL}/projects/projects-lite`,
  PROJECTS_ADVERTISING: `${BASE_URL}/projects/projects-advertising`,
  PROJECT_ITEMS: `${BASE_URL}/projects/project-items`,
  PROJECTS_WORK_LOGS: `${BASE_URL}/projects/work-logs`,
  PROJECTS_HISTORY: `${BASE_URL}/projects/projects-access-history`,
  TIME_LOG: `${BASE_URL}/projects/time-log`,
  REQUEST_TIME_LOG: `${BASE_URL}/projects/request-time-log`,
  TIME_LOG_REPORT: `${BASE_URL}/projects/time-log/general-report`,
  TIME_LOG_TOTAL: `${BASE_URL}/projects/time-log/sum`,
  PROJECT_TASKS: `${BASE_URL}/projects/tasks`,
  PROJECT_TASKS_TREE: `${BASE_URL}/projects/tasks/tree`,
  MASS_DOWNLOAD: `${BASE_URL}/mass-download`,
  RECENT_TASKS: `${BASE_URL}/projects/tasks/last-updated`,
  TASK_CATEGORIES: `${BASE_URL}/projects/task-categories`,
  PRODUCTS: `${BASE_URL}/billing/product-sources`,
  PRODUCT_TYPES: `${BASE_URL}/billing/product-types`,
  COMMENT_TEMPLATES: `${BASE_URL}/projects/comment-templates`,
  COMMENT_TEMPLATE_TAGS: `${BASE_URL}/projects/comment-template-tags`,

  TAGS: `${BASE_URL}/tags`,

  // INTEGRATIONS
  INTEGRATIONS: `${BASE_URL}/settings/integrations`,
  INTEGRATION_STATUS: `${BASE_URL}/settings/integrations/status`,
  INTEGRATION_QUICKBOOKS: `${BASE_URL_PLAIN}/oauth/quickbooks/start`,

  // SERVICES
  SERVICES: `${BASE_URL}/projects/services`,

  // BLOG
  POSTS: `${BASE_URL}/blog/posts`,
  POST_CATEGORIES: `${BASE_URL}/blog/categories`,
  POST_TAGS: `${BASE_URL}/blog/tags`,
  CMS: `${BASE_URL}/cms`,
  FORMS: `${BASE_URL}/forms/forms`,
  FORMS_BLUE_PRINTS: `${BASE_URL}/forms/blueprint-forms`,
  FORM_SUBMISSION_RESEND: `${BASE_URL}/forms/resend-answer-notification`,
  FORMS_RECURRENCE: `${BASE_URL}/forms/recurrency-forms`,
  ANSWERS: `${BASE_URL}/forms/answers`,
  PUBLIC_ANSWERS: `${BASE_URL}/public-answers`,

  // // CMS
  // CMS: {
  //   LOGIN: `${CMS_URL}/auth/local`,
  //   DECKS: `${CMS_URL}/decks`,
  //   POSTS: `${CMS_URL}/posts`,
  //   TEMPLATES: `${CMS_URL}/templates`,
  //   DOCUMENTATIONS: `${CMS_URL}/documentations`,
  //   CAREERS: `${CMS_URL}/careers`,
  //   EBOOKS: `${CMS_URL}/ebooks`,
  //   EMPLOYEE_RESOURCES: `${CMS_URL}/employees`,
  //   PROTOCOLS: `${CMS_URL}/protocols`,
  //   RESOURCES: `${CMS_URL}/resources`,
  //   USERS: `${CMS_URL}/users`,
  //   ONBOARD_INSTRUCTIONS: `${CMS_URL}/onboard-instructions`,
  //   WRIKE_TRAININGS: `${CMS_URL}/wrike-trainings`,
  //   FORMS: `${BASE_URL}/type-form/forms`,
  //   SERVICES: `${BASE_URL}/billing/services`,
  // },

  // UPLOAD
  CREATE_PRE_SIGNED: `${BASE_URL}/create-pre-signed-url`,
  UPLOADS: `${BASE_URL}/uploads`,

  // INTEGRATIONS
  GOOGLE_AUTH: `${BASE_URL}/google/get-auth-url`,
  GOOGLE_EXCHANGE_CODE: `${BASE_URL}/google/exchange-code`,
  CALENDAR_EVENTS: `${BASE_URL}/google/get-events`,
  CALENDARS: `${BASE_URL}/google/get-calendars`,
  GOOGLE_AUTH_TOKEN: `${BASE_URL}/google/get-auth-token`,
  GOOGLE_AUTH_REVOKE: `${BASE_URL}/google/revoke`,
  STRIPE_OVERDUE_INVOICES: `${BASE_URL}/stripe/overdue-invoices`,

  // NOTIFICATIONS
  NOTIFICATIONS: `${BASE_URL}/notifications`,
  FCM_TOKEN: `${BASE_URL}/fcm-token`,

  BULK_OPERATION: `${BASE_URL}/bulk-operation`,

  VERSION: `${BASE_URL}/version`,
};

export default apiRoutes;
