import { FC, useState } from 'react';
import { Button, Card, Col, Form, message, Radio, Row, Space, Typography } from 'antd';
import SelectSearch from 'components/SelectSearch';
import StatusTag from 'components/StatusTag';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { parseTaskTitle } from 'utils/string.utils';

const { Text } = Typography;

interface AddRelationshipProps {
  task: Record<string, any>;
  edit: boolean;
  setEdit: (values: boolean) => void;
  onUpdate: () => Promise<void>;
}

const AddRelationship: FC<AddRelationshipProps> = ({ task, edit, setEdit, onUpdate }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const handleSaveRelation = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      if (values.relation === 'parent') {
        await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${task.uuid}`, {
          parent_id: values.task,
        });
      } else {
        await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${values.task}`, {
          parent_id: task.uuid,
        });
      }

      onUpdate();

      message.success('Relation updated');

      form.resetFields();

      setEdit(false);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return edit ? (
    <div className="w-full" style={{ width: '100%' }}>
      <Card className="mb-l">
        <Form form={form}>
          <SelectSearch
            rules={[
              {
                required: true,
                message: 'This Field is required.',
              },
            ]}
            defaultParams={{
              'filters[project.uuid][]': task.project.uuid,
              'filters[task_status]': [
                'todo',
                'in_progress',
                'waiting_approval',
                'working_on_now',
                'pending',
                'approved',
                'needs_attention',
              ],
              'filters[project_status]': task.project.status === 'blueprint' ? ['blueprint'] : undefined,
            }}
            filterBeforeRender={(preRenderedData: any) => {
              const newData = [...preRenderedData];
              return newData?.filter((el: any) => {
                return el.value !== task.uuid && el?.uuid !== task.parent?.uuid && el.parent?.uuid !== task.uuid;
              });
            }}
            render={(item: any) => (
              <Row wrap={false} justify="space-between" align="middle" gutter={[16, 0]}>
                <Col flex="auto">
                  <Text ellipsis>{parseTaskTitle(item)}</Text>
                </Col>
                <Col flex="none">
                  <StatusTag value={item.task_status} />
                </Col>
              </Row>
            )}
            name="task"
            label="Task"
            labelKey="task_title"
            className="mb-m"
            allowClear
            style={{ width: '100%' }}
            formItemClassName="mb-0"
            api={apiRoutes.PROJECT_TASKS}
          />
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This Field is required.',
              },
            ]}
            className="mb-m"
            label="Relationship"
            name="relation"
          >
            <Radio.Group>
              <Radio value="parent">Parent</Radio>
              <Radio value="child">Child</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <Space>
          <Button loading={loading} onClick={handleSaveRelation} type="primary">
            Save Relationship
          </Button>
          <Button loading={loading} onClick={toggleEdit}>
            Cancel
          </Button>
        </Space>
      </Card>
    </div>
  ) : null;
};

export default AddRelationship;
