import { UploadProps } from 'antd';
import { UploadFileApi } from 'types/upload-type';
import { useRef } from 'react';
import apiRequests from './api';
import apiRoutes from '../config/apiRoute';
import asyncErrorHandler from './asyncErrorHandler';

const useUploadDownload = () => {
  const filesDownloadingRef = useRef<string[]>([]);

  const onDownload: UploadProps['onDownload'] = (upload: UploadFileApi) => {
    const uuid = upload.response?.uuid;

    if (!uuid || !upload.response?.stated || filesDownloadingRef.current.includes(upload.uid)) {
      window.open(upload.url, '_blank');
      return;
    }

    filesDownloadingRef.current.push(uuid);

    apiRequests
      .get(`${apiRoutes.UPLOADS}/${uuid}/download`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        link.download = upload.name;
        link.click();
      })
      .catch((error) => {
        asyncErrorHandler(error);
      })
      .finally(() => {
        const index = filesDownloadingRef.current.indexOf(uuid);

        if (index > -1) {
          filesDownloadingRef.current.splice(index, 1);
        }
      });
  };

  return { onDownload };
};

export default useUploadDownload;
