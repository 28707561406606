import { call, put, StrictEffect, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'utils/interface';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import { PushTokensResponse } from 'types';
import {
  loadUser,
  login,
  logout,
  registerPushToken,
  setAuthData,
  setAuthLoading,
  setLoginLoading,
  setLoginError,
  updateAuthData,
  updatePushToken,
} from './auth-slice';

const { post, get, put: putApi } = apiRequests;

export function* loginSagaListener({ payload }: { payload: { data: any; onLoaded?: () => void }; type: string }): any {
  try {
    localStorage.clear();

    yield put(setLoginLoading(true));

    const res = yield call(post, apiRoutes.LOGIN, {
      cookie_login: false,
      ...payload.data,
    });

    yield put(setAuthData(res.data.user));

    payload.onLoaded?.();
  } catch (error) {
    yield put(setLoginError(error?.response?.data?.message ?? 'Sorry, a problem occurred, please try again'));
  }
}

export function* loadUserSagaListener({ payload }: { payload?: { onLoaded?: () => void }; type: string }): any {
  try {
    yield put(setAuthLoading(true));

    const res: AxiosResponse = yield call(get, apiRoutes.USER_INFO, {}, {}, undefined, true);

    yield put(updateAuthData(res.data));

    payload?.onLoaded?.();
  } catch (error) {
    yield put(updateAuthData(null));
  }
}

export function* logoutSagaListener({ payload }: { payload?: { onLoaded?: () => void }; type: string }): any {
  try {
    yield put(updateAuthData(null));

    yield put({ type: 'store/reset' });

    localStorage.clear();

    payload?.onLoaded?.();
  } catch (error) {
    yield put(loadUser());
  }
}

export function* registerPushTokenListener({ payload: newToken }: { payload: string; type: string }): any {
  const { push_tokens: pushTokens }: { push_tokens: PushTokensResponse[] | null } = yield select((store) => store.auth.user);
  const tokens = pushTokens ?? [];

  const prevTokenIndex = tokens.findIndex((value) => value.token.split(':')[0] === newToken.split(':')[0]);

  try {
    if (prevTokenIndex > -1) {
      const prevToken = tokens[prevTokenIndex];

      yield call(putApi, `${apiRoutes.FCM_TOKEN}/${prevToken.token}`, {
        token: newToken,
      });

      const newTokens = [...tokens];

      newTokens[prevTokenIndex] = {
        ...newTokens[prevTokenIndex],
        token: newToken,
      };

      yield put(updatePushToken(newTokens));
    } else {
      const res: AxiosResponse = yield call(post, apiRoutes.FCM_TOKEN, {
        token: newToken,
      });

      yield put(updatePushToken([...tokens, res.data.data]));
    }
  } catch (error) {
    asyncErrorHandler(error);
  }
}

/**
 * watcher saga
 */
function* authSaga(): Generator<StrictEffect> {
  yield takeLatest(login.type, loginSagaListener);
  yield takeLatest(loadUser.type, loadUserSagaListener);
  yield takeLatest(logout.type, logoutSagaListener);
  yield takeLatest(registerPushToken.type, registerPushTokenListener);
}

export default authSaga;
