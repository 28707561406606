import { CSSProperties, FC } from 'react';
import { Button, Col, FormInstance, Popconfirm, Row, Typography } from 'antd';
import { convertMinsToHrsMins, convertSnakeCaseToTitleCase } from 'utils/string.utils';
import styles from './Timer.module.less';
import TimerForm from './TimerForm';

const { Text } = Typography;

interface TimerContainerProps {
  dataLog?: Record<string, any>;
  form: FormInstance;
  style?: CSSProperties;
  projectId?: string;
  title?: string;
  modalStatus?: string;
  totalTime?: number;
  loading?: boolean;
  loadingTotal?: boolean;
  globalActiveTimer?: boolean;
  needsAlert?: boolean;
  requiredDescription?: boolean;
  showUserSelection?: 'task' | 'request';
  onAddEntry: () => void;
  onChangeTimer?: (value: string) => void;
  onResetTimer?: () => void;
  onCancel: () => void;
}

const TimerContainer: FC<TimerContainerProps> = ({
  dataLog,
  form,
  title,
  style,
  projectId,
  modalStatus,
  totalTime,
  loading,
  loadingTotal,
  globalActiveTimer,
  needsAlert,
  requiredDescription,
  showUserSelection,
  onAddEntry,
  onChangeTimer,
  onResetTimer,
  onCancel,
}) => {
  modalStatus = modalStatus ?? dataLog?.task?.status;

  return (
    <div className={styles.dropdown} style={style}>
      {globalActiveTimer || title ? (
        <div className={styles.head}>
          <span>{title ?? dataLog?.task?.title}</span>
        </div>
      ) : undefined}
      <div className={styles.body}>
        <TimerForm
          form={form}
          projectId={projectId}
          showUserSelection={showUserSelection}
          requiredDescription={requiredDescription}
          onChangeTimer={onChangeTimer}
        />

        {totalTime !== undefined && (
          <div className="mb-m">
            <Text type={loadingTotal ? 'secondary' : undefined}>
              Your time spent:&nbsp;
              {convertMinsToHrsMins(totalTime).split(':')[0]}h&nbsp;
              {convertMinsToHrsMins(totalTime).split(':')[1]}m
            </Text>
          </div>
        )}

        <Row justify="space-between">
          <Row gutter={3}>
            <Col>
              {(modalStatus === 'completed' || modalStatus === 'cancelled') && needsAlert ? (
                <Popconfirm
                  placement="topLeft"
                  disabled={loading}
                  onConfirm={onAddEntry}
                  title={`This task is ${convertSnakeCaseToTitleCase(modalStatus)}, are you sure you want to add the timelog?`}
                >
                  <Button loading={loading} type="primary">
                    Add entry
                  </Button>
                </Popconfirm>
              ) : (
                <Button loading={loading} onClick={onAddEntry} type="primary">
                  Add entry
                </Button>
              )}
            </Col>

            <Col>
              <Button className="primary" onClick={onCancel} type="text">
                Cancel
              </Button>
            </Col>
          </Row>

          {onResetTimer && (
            <Button onClick={onResetTimer} disabled={!dataLog} className="primary" type="text">
              Reset timer
            </Button>
          )}
        </Row>
      </div>
    </div>
  );
};

export default TimerContainer;
