import { useMemo } from 'react';
import { Row } from 'antd';
import get from 'lodash/get';
import { parseProjectTitle } from 'utils/string.utils';
import StatusTag from 'components/StatusTag';
import useWebSocket from 'utils/useWebSocket';
import { useDispatch } from 'react-redux';
import { getProjectSelect } from '../../store/projectSelect-slice';

const searchKeys = ['title', 'customer.business_name', 'customer.abbreviation'];

interface UseProjectSelectOptions {
  projects?: Record<string, any>[];
  withPersonal?: boolean;
  onlyRetainer?: boolean;
  onlyActive?: boolean;
}

const useProjectSelect = ({ projects, withPersonal, onlyRetainer, onlyActive }: UseProjectSelectOptions) => {
  const dispatch = useDispatch();

  const options = useMemo(() => {
    const filterProject = (project: any) => {
      return (
        (withPersonal || project.kind !== 'personal') &&
        (!onlyRetainer || project.is_retainer) &&
        (!onlyActive || project.status === 'active')
      );
    };

    const sortProject = (a: any, b: any) => {
      if (
        (a.extra.status === 'active' && b.extra.status === 'active') ||
        (a.extra.status !== 'active' && b.extra.status !== 'active')
      ) {
        if (a.display === b.display) {
          return 0;
        }

        return a.display > b.display ? 1 : -1;
      }

      return a.extra.status === 'active' ? -1 : 1;
    };

    return projects
      ?.filter(filterProject)
      .map((project: any) => ({
        value: project.uuid,
        display: parseProjectTitle(project),
        extra: project,
        label: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '5px 12px',
              background: project.status === 'cancelled' || project.status === 'completed' ? '#e4e4e4' : undefined,
            }}
          >
            <Row justify="space-between">
              {parseProjectTitle(project)}
              <StatusTag value={project.status} />
            </Row>
            <span className="primary sub">{project.customer?.business_name}</span>
          </div>
        ),
      }))
      .sort(sortProject);
  }, [projects, onlyActive, onlyRetainer, withPersonal]);

  const onDropdownVisibleChangeHandler = (open: boolean) => {
    if (open) {
      dispatch(getProjectSelect());
    }
  };

  const filterOption = (search: string, option: any) => {
    return !!searchKeys.find((key) => (get(option?.extra, key) ?? '').toLowerCase().includes(search.toLowerCase()));
  };

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.project',
      callback: () => dispatch(getProjectSelect()),
    },
  });

  return {
    options,
    onDropdownVisibleChangeHandler,
    filterOption,
  };
};

export default useProjectSelect;
