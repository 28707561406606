import { Typography } from 'antd';
import { FC, ReactNode } from 'react';
import { convertSnakeCaseToTitleCase } from '../../utils/string.utils';

const { Text } = Typography;

interface UpdatedTextLogProps {
  title: string;
  children?: ReactNode;
}

const UpdatedTextLog: FC<UpdatedTextLogProps> = ({ title, children }) => {
  return (
    <Text>
      Updated {convertSnakeCaseToTitleCase(title, false)}
      {children !== undefined && (
        <>
          {' '}
          to <Text strong>{children}</Text>
        </>
      )}
    </Text>
  );
};

export default UpdatedTextLog;
