/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createContext, FC, ReactNode, useEffect } from 'react';
import { Button, Col, Form, FormInstance, Pagination, Row, Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { EyeOutlined } from '@ant-design/icons';
import BulkDeleteModal from './components/BulkDeleteModal';
import ExportModal from './components/ExportModal';
import useCustomTable from './useCustomTable';
import { FiltersOption } from './components/FilterMenu';
import ActionRow from './components/ActionRow';
import EditableRow from './components/EditableRow';
import EditableCell from './components/EditableCell';
import { checkIsSafari } from '../../utils/extra.utils';
import ActionButtons from './components/ActionButtons';
import styles from './customTable.module.less';

export const EditableContext = createContext<FormInstance<any> | null>(null);

export interface TableColumn {
  title?: string | null;
  dataIndex: string | string[];
  sorter?: boolean;
  search?: boolean;
  fixed?: 'right' | 'left';
  width?: number;
  render?: (value: any, row: any) => any;
  sorterKey?: string;
  key?: string;
  align?: 'left' | 'right' | 'center';
  editable?: boolean;
  editComponent?: ReactNode;
  onToggleEdit?: any;
  onSaveRecord?: any;
  minSpace?: boolean;
  minSpaceText?: (value: any, row: any) => string;
  exportRender?: any;
  exportRenderTheme?: 'status' | 'users' | 'customer' | 'customers' | 'array' | 'titleCaseArray';
  hideFromExport?: boolean;
  customExportKey?: string;
}

interface ButtonsProps {
  label?: string;
  onClick?: any;
  render?: (selected: any, index: any) => any;
  props?: any;
}

interface BulkOpsOption {
  label: string;
  onClick?: any;
}

interface CustomTableProps {
  columns: TableColumn[];
  data: any;
  loading?: boolean;
  dispatch?: () => any;
  hidePagination?: boolean;
  pagination?: any;
  rowKey?: string;
  onRowClick?: any;
  onDoubleClick?: any;
  buttons?: ButtonsProps[];
  exportAllApi?: string;
  tableKey: string;
  bulkOpsOptions?: BulkOpsOption[];
  filterLabel?: string;
  filtersOptions?: FiltersOption[];
  bulkLoading?: boolean;
  hideBulkStatus?: boolean;
  hideBulkDelete?: boolean;
  hideActionBar?: boolean;
  hideRowSelection?: boolean;
  defaultSorter?: any;
  className?: string;
  containerClassName?: string;
  customTrigger?: any;
  size?: SizeType;
  showHeader?: boolean;
  defaultFilter?: any;
  defaultParams?: any;
  exportDefaultParams?: any;
  multipleFilters?: boolean;
  unmountDispatch?: any;
  showResetFilter?: boolean;
  showAssigneesFilter?: boolean;
  actionFilters?: any;
  advancedFilters?: any;
  advancedFiltersStart?: any;
  advancedFiltersCount?: number;
  hideSearch?: boolean;
  searchField?: string;
  searchArray?: boolean;
  editable?: boolean;
  onRowUpdate?: any;
  widthX?: any;
  widthY?: any;
  hideDefaultBulkOptions?: boolean;
  showCategoryFilter?: boolean;
  hideActionButton?: any;
  summary?: any;
  expandable?: any;
  expandedRowRender?: any;
  childrenColumnName?: any;
  footer?: any;
  filterRender?: any;
  actionButtonIcon?: any;
  renderParams?: any;
  onResetFilters?: any;
  hideScroll?: boolean;
  onReload?: any;
  customFooter?: any;
  middleChildren?: any;
  onInit?: any;
  customRowSelection?: any;
  onRowSelection?: any;
  countOnlyChildren?: boolean;
  hideSelectAllFields?: boolean;
  hideReloadButton?: boolean;
  collapseAdvancedFilters?: boolean;
  fullPage?: boolean;
  withScroll?: boolean;
  containerTable?: (table: any, buttonActions: any) => ReactNode;
  onFiltersChanged?: (params: any) => void;
}

const CustomTable: FC<CustomTableProps> = ({
  columns,
  buttons = [],
  className,
  containerClassName = '',
  size = 'small',
  showHeader,
  data,
  loading,
  dispatch,
  editable = false,
  hidePagination,
  pagination = {},
  onDoubleClick,
  hideBulkDelete,
  bulkOpsOptions = [],
  tableKey,
  exportAllApi,
  hideBulkStatus = true,
  rowKey,
  bulkLoading,
  defaultSorter,
  hideActionBar,
  filterRender,
  filterLabel,
  filtersOptions = [],
  hideRowSelection,
  defaultFilter,
  defaultParams,
  exportDefaultParams,
  customTrigger,
  multipleFilters = false,
  onRowClick,
  unmountDispatch,
  showAssigneesFilter = false,
  showResetFilter,
  actionFilters,
  advancedFilters,
  advancedFiltersStart,
  advancedFiltersCount,
  hideSearch,
  searchField,
  searchArray,
  onRowUpdate,
  widthX,
  hideDefaultBulkOptions,
  showCategoryFilter,
  hideActionButton,
  summary,
  expandable,
  expandedRowRender,
  childrenColumnName,
  actionButtonIcon,
  footer,
  renderParams,
  onResetFilters,
  hideScroll,
  onReload,
  customFooter,
  middleChildren,
  onInit,
  widthY,
  customRowSelection = {},
  onRowSelection,
  countOnlyChildren,
  hideSelectAllFields,
  hideReloadButton = false,
  collapseAdvancedFilters = false,
  fullPage = false,
  withScroll = false,
  containerTable,
  onFiltersChanged,
}) => {
  const {
    state,
    stickyState,
    rowSelection,
    updatedColumns,
    setState,
    onSelectAll,
    handleSearch,
    toggleExportModal,
    handleTableChange,
    handleParseToCSV,
    handleParseToXLSL,
    handleClearFilters,
    handleStatus,
    handleDeleteBulk,
    handleSetFilter,
    setStickyState,
    triggerEffect,
  } = useCustomTable({
    defaultFilter,
    dispatchFunction: dispatch,
    columns,
    data,
    exportAllApi,
    tableKey,
    pagination,
    defaultSorter,
    customTrigger,
    defaultParams,
    searchField,
    searchArray,
    exportDefaultParams,
    unmountDispatch,
    editable,
    onRowUpdate,
    renderParams,
    onRowSelection,
    onFiltersChanged,
  });
  const {
    exportModal,
    exportModalFormat,
    exporting,
    bulkLoading: _bulkLoading,
    bulkModal,
    selectedRowKeys,
    selectAllCurrentRows,
    selectAllRows,
  } = state;
  const { pageSize, current, search, filter } = stickyState;
  const [assigneesForm] = Form.useForm();

  useEffect(() => {
    if (onInit) {
      onInit({ setStickyState, assigneesForm, stickyState, handleSetFilter, filter, handleSearch });
    }
  }, []);

  const renderComponents = editable
    ? {
        body: {
          row: EditableRow,
          cell: (props: any) => {
            if (!props.editable) {
              return <td {...props} />;
            }

            const currentEl = updatedColumns?.find((el) => el?.dataIndex === props?.dataIndex);

            return (
              <EditableCell
                {...props}
                onToggleEdit={currentEl?.onToggleEdit}
                onSaveRecord={currentEl?.onSaveRecord}
                editComponent={currentEl?.editComponent}
              />
            );
          },
        },
      }
    : undefined;

  const scrollX = !data?.length ? widthX ?? true : 'max-content';
  const defaultScrollY = fullPage ? 'calc(100% - 24px)' : window.innerHeight - 450;

  const table = (
    <div className={styles.tableContainer}>
      <Table
        childrenColumnName={childrenColumnName}
        expandedRowRender={expandedRowRender}
        rowClassName={editable ? () => 'editable-row' : undefined}
        components={renderComponents}
        scroll={
          hideScroll ? undefined : { y: widthY ?? defaultScrollY, x: editable && checkIsSafari() ? widthX ?? true : scrollX }
        }
        className={`${className ?? ''}`}
        columns={
          loading && (!data || data?.length === 0)
            ? []
            : [
                ...updatedColumns,
                ...(onDoubleClick && !hideActionButton
                  ? [
                      {
                        width: 40,
                        title: '',
                        dataIndex: 'action',
                        fixed: 'right',
                        render: (_: any, row: any) => {
                          return (
                            <Button
                              size="small"
                              type="link"
                              icon={actionButtonIcon ?? <EyeOutlined />}
                              onClick={() => onDoubleClick(row)}
                            />
                          );
                        },
                      },
                    ]
                  : []),
              ]
        }
        footer={footer}
        expandable={expandable}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        onRow={(r) => ({
          onClick: () => onRowClick && onRowClick(r),
          onDoubleClick: () => onDoubleClick && onDoubleClick(r),
        })}
        pagination={false}
        rowKey={rowKey ?? 'uuid'}
        rowSelection={hideRowSelection ? undefined : { ...rowSelection, ...customRowSelection }}
        size={size}
        showHeader={showHeader ?? true}
        style={!fullPage ? { marginTop: containerTable ? 4 : 16 } : undefined}
        summary={summary}
        showSorterTooltip={false}
      />

      <Row justify="space-between" className={styles.footer}>
        <Col>{customFooter}</Col>

        <Col>
          {!hidePagination && (
            <Pagination
              size={size}
              showSizeChanger
              {...pagination}
              onChange={(_page, _pageSize) => {
                setStickyState((prevState: any) => ({
                  ...prevState,
                  current: _pageSize !== pageSize ? 1 : _page,
                  pageSize: _pageSize,
                }));
              }}
              pageSize={pageSize}
              current={current}
            />
          )}
        </Col>
      </Row>
    </div>
  );

  return (
    <div
      className={`${styles.container} ${fullPage ? styles.fullPage : ''} ${
        withScroll ? styles.withScroll : ''
      } ${containerClassName}`}
    >
      <BulkDeleteModal
        count={selectAllRows ? pagination?.total : selectedRowKeys?.length}
        loading={_bulkLoading}
        onClose={() => {
          setState((prevState) => ({ ...prevState, bulkModal: false }));
        }}
        onOk={handleDeleteBulk}
        visible={bulkModal}
      />

      <ExportModal
        exporting={exporting}
        exportModal={exportModal}
        exportModalFormat={exportModalFormat}
        handleParseToCSV={handleParseToCSV}
        handleParseToXLSL={handleParseToXLSL}
        selectedRowKeys={selectedRowKeys}
        setState={setState}
        toggleExportModal={toggleExportModal}
      />

      <ActionRow
        hideSelectAllFields={hideSelectAllFields}
        assigneesForm={assigneesForm}
        onReload={onReload}
        onResetFilters={onResetFilters}
        filterRender={filterRender}
        actionFilters={actionFilters}
        advancedFiltersStart={advancedFiltersStart}
        advancedFilters={advancedFilters}
        advancedFiltersCount={advancedFiltersCount}
        showCategoryFilter={showCategoryFilter}
        hideSearch={hideSearch}
        hideActionBar={hideActionBar}
        showAssigneesFilter={showAssigneesFilter}
        filtersOptions={filtersOptions}
        search={search}
        handleSearch={handleSearch}
        filter={filter}
        handleSetFilter={handleSetFilter}
        defaultFilter={defaultFilter}
        buttons={buttons}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        _bulkLoading={_bulkLoading}
        bulkLoading={bulkLoading}
        handleClearFilters={handleClearFilters}
        multipleFilters={multipleFilters}
        showResetFilter={showResetFilter}
        toggleExportModal={toggleExportModal}
        triggerEffect={triggerEffect}
        bulkOpsOptions={bulkOpsOptions}
        handleStatus={handleStatus}
        hideBulkDelete={hideBulkDelete}
        hideBulkStatus={hideBulkStatus}
        selectAllRows={selectAllRows}
        countOnlyChildren={countOnlyChildren}
        onSelectAll={onSelectAll}
        pagination={pagination}
        selectAllCurrentRows={selectAllCurrentRows}
        data={data}
        hideDefaultBulkOptions={hideDefaultBulkOptions}
        filterLabel={filterLabel}
        hideExportButton={!!containerTable || !exportAllApi}
        hideReloadButton={!!containerTable || hideReloadButton}
        collapseAdvancedFilters={collapseAdvancedFilters}
      />

      {middleChildren}

      {containerTable
        ? containerTable(
            table,
            <ActionButtons
              showExportButton={!!exportAllApi}
              showReloadButton={!hideReloadButton}
              loadingExportButton={bulkLoading || _bulkLoading}
              loadingReloadButton={loading}
              onExport={toggleExportModal}
              onReload={onReload ?? triggerEffect}
            />,
          )
        : table}
    </div>
  );
};

export default CustomTable;
