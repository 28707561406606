import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import { IoClose } from 'react-icons/io5';
import axios, { AxiosResponse } from 'axios';
import { Col, Row, Select, SelectProps, Tooltip, Typography } from 'antd';
import type { RefSelectProps } from 'antd/es/select';
import UserAvatar from 'components/UserAvatar';
import apiRoutes from 'config/apiRoute';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';
import { debounceAsync } from 'utils/debounce';
import styles from './index.module.less';

type SelectUserAvatarOptions = Required<SelectProps<any>>['options'];

const { Text } = Typography;

interface SelectUserAvatarProps {
  value?: string | string[];
  placeholder?: string;
  defaultParams?: Record<string, any>;
  defaultOptions?: SelectUserAvatarOptions;
  projectId?: string;
  mode?: SelectProps<any>['mode'];
  size?: SelectProps<any>['size'];
  colorLabel?: 'default' | 'secondary';
  filterType?: ('regular_user' | 'contact' | 'freelancer')[];
  bordered?: boolean;
  onlyAvatar?: boolean;
  disabled?: boolean;
  filterRender?: (values: SelectUserAvatarOptions) => SelectUserAvatarOptions;
  dropdownMatchSelectWidth?: boolean | number;
  onChange?: (value: any, option: any) => void;
}

const debounceFetchFactory = () => {
  return debounceAsync<AxiosResponse>((url: string, params: any) => {
    return apiRequests.get(url, params);
  }, 300);
};

const SelectUserAvatar: FC<SelectUserAvatarProps> = ({
  value,
  placeholder,
  defaultParams,
  defaultOptions,
  projectId,
  mode,
  size = 'large',
  colorLabel,
  filterType,
  bordered = false,
  onlyAvatar = false,
  disabled,
  filterRender,
  dropdownMatchSelectWidth,
  onChange,
}) => {
  const [options, setOptions] = useState<SelectUserAvatarOptions>(defaultOptions ?? []);
  const [loading, setLoading] = useState(false);

  const selectRef = useRef<RefSelectProps>(null);
  const debounceGetOptionsRef = useRef<ReturnType<typeof debounceFetchFactory>>();

  if (!debounceGetOptionsRef.current) {
    debounceGetOptionsRef.current = debounceFetchFactory();
  }

  const fetchUsers = async (search = '') => {
    if (!debounceGetOptionsRef.current) return;

    setLoading(true);

    const url = projectId ? `${apiRoutes.BASE_URL}/projects/${projectId}/users` : apiRoutes.PROJECT_USERS;

    try {
      const { data } = await debounceGetOptionsRef.current(url, {
        'page[size]': 50,
        ...defaultParams,
        search_term: search ?? undefined,
      });

      const newOptions = data.data
        .map((item: any) => ({ value: item.uuid, label: item.name, user: item }))
        .filter(
          (item: any) =>
            (!filterType || filterType.includes(item.user.type)) &&
            (!defaultOptions || !defaultOptions.find((x: any) => x.value === item.value)),
        );

      setOptions([...(defaultOptions ?? []), ...newOptions]);
    } catch (error) {
      if (!axios.isCancel(error)) {
        asyncErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const tagRender: SelectProps<any>['tagRender'] = (props) => {
    const { value: propValue, onClose } = props;
    const onPreventMouseDown = (event: MouseEvent<any>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const option = [...(defaultOptions ?? []), ...options].find((item) => item.value === propValue);

    return (
      <div className={styles.avatarContainer}>
        {!disabled && (
          <Tooltip title={`Remove ${option?.label}`} placement="top">
            <button type="button" onMouseDown={onPreventMouseDown} onClick={onClose} className={styles.removeUser}>
              <IoClose />
            </button>
          </Tooltip>
        )}

        <Tooltip title={option?.label} placement="top">
          <UserAvatar size="small" user={option?.user} />
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    if (options.length > (defaultOptions?.length ?? 0)) {
      setOptions(defaultOptions ?? []);
    }

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <Select
      ref={selectRef}
      mode={mode}
      loading={loading}
      placeholder={placeholder}
      size={size}
      value={value}
      maxTagCount="responsive"
      className={`${styles.select} ${onlyAvatar ? styles.onlyAvatar : ''}`}
      suffixIcon={null}
      removeIcon={onlyAvatar ? null : undefined}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      bordered={bordered}
      maxTagTextLength={2}
      maxTagPlaceholder={(omittedValues) => {
        return <span>+{omittedValues.length}</span>;
      }}
      showArrow={false}
      disabled={disabled}
      showSearch
      allowClear
      filterOption={(input, option: any) => option.label.toLowerCase().includes(input.toLowerCase())}
      tagRender={onlyAvatar ? tagRender : undefined}
      onSearch={fetchUsers}
      onChange={(current) => {
        const selected = Array.isArray(current)
          ? options.filter((item) => current.includes(item.value))
          : options.find((item) => item.value === current);

        onChange?.(current, selected);
        fetchUsers();
      }}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          if (mode !== 'multiple') {
            selectRef.current?.blur();
          }

          fetchUsers();
        }
      }}
    >
      {(filterRender ? filterRender(options) : options).map((item) => (
        <Select.Option key={item.value} label={item.label} value={item.value}>
          <Row wrap={false} align="middle" gutter={[6, 0]}>
            <Col style={{ lineHeight: 1.7 }}>
              <UserAvatar size="small" user={item.user} />
            </Col>

            <Col flex="auto" className={styles.nameCol}>
              <Text className={colorLabel === 'secondary' ? styles.secondaryLabel : ''} ellipsis>
                {item.label}
              </Text>
            </Col>
          </Row>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectUserAvatar;
