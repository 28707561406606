/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Select, Typography } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';
import { OptionProps } from 'antd/es/select';
import uniqBy from 'lodash/uniqBy';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { debounceAsync } from 'utils/debounce';

const { Text } = Typography;

const debounceFetchFactory = () => {
  return debounceAsync<AxiosResponse>((url: string, params: any) => {
    return apiRequests.get(url, params);
  }, 300);
};

interface SelectSearchProps {
  api: string;
  labelKey?: string;
  valueKey?: string;
  name?: string;
  label?: ReactNode;
  rules?: Rule[];
  customValidator?: (rule: RuleObject, value: any, item: any) => Promise<void>;
  className?: any;
  initialOptions?: any[];
  initialValue?: any;
  startingOptions?: any[];
  initialOption?: any;
  defaultFilter?: any;
  labelCol?: any;
  mode?: any;
  style?: CSSProperties;
  formItemClassName?: string;
  placeholder?: string;
  render?: any;
  onChange?: (selected: any, values: any) => void;
  defaultParams?: any;
  size?: string;
  disabled?: boolean;
  searchByLabelKey?: boolean;
  onLoad?: any;
  allowClear?: boolean;
  searchKey?: any;
  filterBeforeRender?: any;
  renderOptionProps?: (item: Record<string, any>) => Partial<OptionProps>;
  onBlur?: any;
  customSearchField?: any;
  maxLength?: any;
  noMargin?: boolean;
  noResponsive?: boolean;
  noStyle?: boolean;
  bordered?: boolean;
  suffixIcon?: ReactNode;
  tagRender?: (props: {
    item: any;
    label: React.ReactNode;
    value: any;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
  }) => ReactElement;
}

const SelectSearch: FC<SelectSearchProps> = ({
  labelKey = 'name',
  valueKey = 'uuid',
  name,
  label,
  rules,
  customValidator,
  api,
  className,
  initialOptions = [],
  initialValue,
  initialOption,
  defaultFilter,
  labelCol,
  mode,
  style,
  formItemClassName,
  startingOptions = [],
  filterBeforeRender,
  placeholder,
  customSearchField,
  render,
  size,
  onChange,
  defaultParams = {},
  disabled,
  searchByLabelKey,
  searchKey,
  onLoad,
  allowClear,
  renderOptionProps,
  onBlur,
  noMargin = false,
  noResponsive,
  noStyle,
  bordered,
  suffixIcon,
  tagRender,
}) => {
  const debounceGetOptionsRef = useRef<ReturnType<typeof debounceFetchFactory>>();

  if (!debounceGetOptionsRef.current) {
    debounceGetOptionsRef.current = debounceFetchFactory();
  }

  const singleOption = [];

  if (initialOption) {
    singleOption.push(initialOption);
  }

  const [state, setState] = useState({
    loading: false,
    searching: false,
    data: [
      ...[...startingOptions, ...singleOption, ...initialOptions].map((el) => ({
        ...el,
        value_copy: el?.value_copy ?? el?.value,
        label: el[labelKey],
        value: el[valueKey],
      })),
    ] as any,
    search: null as any,
  });

  const { data, loading } = state;

  const fetchData = async (search = '') => {
    if (!debounceGetOptionsRef.current) return;

    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      const params: any = { ...defaultParams };

      if (search) {
        if (searchByLabelKey || searchKey) {
          params[searchKey ?? labelKey] = search;
        } else {
          params['search_term[0][field]'] = customSearchField ?? labelKey;
          params['search_term[0][rule]'] = 'contains';
          params['search_term[0][value]'] = search;
        }
      }

      if (defaultFilter && defaultFilter?.filterBy && defaultFilter?.value) {
        params[`filters[${defaultFilter?.filterBy}][]`] = defaultFilter?.value;
      }

      const res = await debounceGetOptionsRef.current(api, params);

      const getUniqData = (prevState: any) => {
        const newData = res?.data?.data?.map((el: any) => ({
          ...el,
          value_copy: el?.value_copy ?? el?.value,
          label: el[labelKey],
          value: el[valueKey],
        }));
        const uniqData = uniqBy(
          [
            ...startingOptions.map((el) => ({
              ...el,
              value_copy: el?.value_copy ?? el?.value,
              label: el[labelKey],
              value: el[valueKey],
            })),
            ...newData,
            ...prevState.data,
          ],
          'value',
        );

        if (onLoad) {
          onLoad(uniqData);
        }

        return uniqData;
      };

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: getUniqData(prevState),
      }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [defaultFilter?.filterBy, defaultFilter?.value]);

  const wrapRules = rules ? [...rules] : [];

  if (customValidator) {
    wrapRules.push({
      validator(rule, value) {
        const item = Array.isArray(value)
          ? data?.filter((el: any) => value.includes(el.value))
          : data?.find((el: any) => el.value === value);

        return customValidator(rule, value, item);
      },
    });
  }

  return (
    <Form.Item
      style={{
        // @ts-ignore
        position: 'relative !important',
        margin: noMargin ? 0 : undefined,
      }}
      className={formItemClassName}
      label={label}
      labelCol={labelCol}
      name={name}
      initialValue={initialValue}
      rules={wrapRules}
      noStyle={noStyle}
    >
      <Select
        maxLength={1}
        onBlur={onBlur}
        allowClear={allowClear}
        dropdownStyle={{ position: 'fixed' }}
        className={className}
        disabled={disabled}
        filterOption={(input, el: any) => el?.label?.toLowerCase().includes(input?.toLowerCase())}
        loading={loading}
        tagRender={
          tagRender ? (props) => tagRender({ item: data.find((item: any) => item.value === props.value), ...props }) : undefined
        }
        maxTagCount={!noResponsive ? 'responsive' : undefined}
        maxTagPlaceholder={(omittedValues) => {
          return <span>+{omittedValues.length}</span>;
        }}
        mode={mode}
        onChange={(value) => {
          const res = Array.isArray(value)
            ? data?.filter((el: any) => value.includes(el.value))
            : data?.find((el: any) => el.value === value);

          onChange?.(res, value);
        }}
        onSearch={fetchData}
        placeholder={placeholder}
        showArrow
        showSearch
        bordered={bordered}
        suffixIcon={suffixIcon}
        size={size as any}
        style={style}
      >
        {(filterBeforeRender ? filterBeforeRender(data) : data)?.map((el: any) => (
          <Select.Option {...(renderOptionProps ? renderOptionProps(el) : {})} key={el.value} label={el.label} value={el.value}>
            <Text strong={el.bold}>{render ? render(el) : el.label}</Text>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectSearch;
