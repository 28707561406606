import { FC, useState } from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';

interface StartDueDatePickerProps {
  value?: [moment.Moment | null, moment.Moment | null] | null;
  onChange?: (value: [moment.Moment | null, moment.Moment | null] | null) => void;
}

const StartDueDatePicker: FC<StartDueDatePickerProps> = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker.RangePicker
      open={open}
      value={value}
      onChange={onChange}
      className="w-full ant-picker-short-format ant-picker-yy"
      size="small"
      format="MMM DD, YY"
      placeholder={['Start date', 'Due date']}
      separator="-"
      renderExtraFooter={() => (
        <div style={{ display: 'flex', justifyContent: 'right', padding: '8px 0' }}>
          <Button type="primary" size="small" onClick={() => setOpen(false)}>
            Ok
          </Button>
        </div>
      )}
      onOpenChange={setOpen}
    />
  );
};

export default StartDueDatePicker;
