/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { Form, FormProps } from 'antd';
import moment from 'moment';
import type { RichTextEditorHandle } from 'components/RichTextEditor';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import useWebSocket from 'utils/useWebSocket';
import { mapModelToUploadFileApi } from 'utils/array.utils';
import { convertHrsMinsToMins, convertMinsToHrsMins, localRandomUuid } from 'utils/string.utils';
import apiRoutes from 'config/apiRoute';
import ENVIRONMENT from 'config/environment';
import type { UploadFileApi } from 'types/upload-type';
import type { AwarenessUser } from 'types/richTextEditor';

const debounceCallback = debounce((callback: () => void) => {
  callback();
}, 600);

interface TaskModalParams {
  uuid: any;
  project?: any;
  initialFiles?: UploadFileApi[];
  createToParentUuid?: string;
  createToStatus?: string;
  createFromTaskUuid?: string;
  isPersonal?: boolean;
  onCustomizeDataApi?: (values: Record<string, any>) => Record<string, any>;
  onSaved?: (data: Record<string, any>) => void;
}

const useTaskModal = ({
  uuid,
  project,
  initialFiles,
  createToParentUuid,
  createToStatus,
  createFromTaskUuid,
  isPersonal,
  onCustomizeDataApi,
  onSaved,
}: TaskModalParams) => {
  const user = useSelector((store: any) => store.auth.user);

  const [filesData, setFilesData] = useState<UploadFileApi[]>(initialFiles ?? []);
  const [fetching, setFetching] = useState(false);
  const [fetchingParent, setFetchingParent] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fetchTaskError, setFetchTaskError] = useState<any | null>(null);
  const [selectedProject, setSelectedProject] = useState<any>(project);
  const [connectedUsers, setConnectedUsers] = useState<Map<string, AwarenessUser>>(() => new Map());
  const [editorStatus, setEditorStatus] = useState('connecting');
  const [hasDirtyFields, setHasDirtyFields] = useState(0);
  const [createToParentTask, setCreateToParentTask] = useState<Record<string, any> | null>(null);

  const history = useHistory();
  const mountedRef = useRef(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const descriptionTextRef = useRef<RichTextEditorHandle>(null);

  const isCreateModal = uuid === 'create';

  const [form] = Form.useForm();

  const [state, setState] = useState<any>(
    isCreateModal
      ? () => ({
          followers: user.preferences.auto_follow_tasks === '1' ? [user] : [],
        })
      : null,
  );

  const [initialValues, setInitialValues] = useState<any>(
    isCreateModal
      ? () => ({
          project_id: project?.uuid ?? null,
          priority: 0,
          start_due_date: [moment(), moment().add(1, 'day')],
          status: createToStatus ?? 'todo',
          followers: user.preferences.auto_follow_tasks === '1' ? [user.uuid] : undefined,
          estimated_time_range: '00:00 - 00:00',
        })
      : undefined,
  );

  const setProject = (_project: any) => {
    setSelectedProject(_project);
    form.setFieldsValue({ project_id: _project.uuid });
  };

  const setData = (data: any, updateForm = false, ignoreKeys: string[] = []) => {
    const followers = data.related_users?.filter((el: any) => el.pivot.type === 'follower');
    const responsibles = data.related_users?.filter((el: any) => el.pivot.type === 'responsible');

    const formValues = {
      title: data.title,
      project_id: data.project.uuid,
      priority: data.priority,
      status: data.status,
      start_due_date: [data.start_date ? moment.utc(data.start_date) : null, data.end_date ? moment.utc(data.end_date) : null],
      responsibles: responsibles?.[0]?.uuid,
      followers: followers?.map((el: any) => el.uuid),
      recurrence: data.status === 'seed' && data.recurrence_type !== 'none',
      recurrence_type: data.recurrence_type,
      recurrence_limit: data.recurrence_limit,
      tags: data.tags.map((x: any) => x.name),
      estimated_time_range: `${convertMinsToHrsMins(data.estimated_time_min)} - ${convertMinsToHrsMins(data.estimated_time)}`,
    };

    const stateValues = {
      ...data,
      responsibles,
      followers,
      recurrence: data.status === 'seed' && data.recurrence_type !== 'none',
    };

    ignoreKeys.forEach((key) => {
      if (key in formValues) {
        delete formValues[key as keyof typeof formValues];
      }

      if (key in stateValues) {
        delete stateValues[key as keyof typeof stateValues];
      }
    });

    setInitialValues(formValues);

    if (updateForm) {
      form.setFieldsValue(formValues);
    }

    if (!ignoreKeys.includes('project_id')) {
      setProject(data.project);
    }

    setState(stateValues);

    setFilesData((prev) => {
      const newFiles = mapModelToUploadFileApi([...data.uploads, ...data.comment_uploads]);

      if (!updateForm) return newFiles;

      return [...newFiles, ...prev.filter((item) => item.status !== 'done')];
    });
  };

  const scrollToBottom = () => {
    const scrollEl = scrollRef.current;

    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  };

  const normalizeFormValues = (values: any) => {
    const newValues = { ...values };

    Object.keys(newValues).forEach((key) => {
      if (newValues[key] === undefined) {
        newValues[key] = null;
      }
    });

    if ('start_due_date' in newValues) {
      newValues.start_date = newValues.start_due_date?.[0] ? moment(newValues.start_due_date[0]).format('YYYY-MM-DD') : null;
      newValues.end_date = newValues.start_due_date?.[1] ? moment(newValues.start_due_date[1]).format('YYYY-MM-DD') : null;
      delete newValues.start_due_date;
    }

    if ('estimated_time_range' in newValues) {
      if (newValues.estimated_time_range) {
        const [hourMin, hourMax] = newValues.estimated_time_range.split('-');

        newValues.estimated_time_min = hourMin ? convertHrsMinsToMins(hourMin) : 0;
        newValues.estimated_time = hourMax ? convertHrsMinsToMins(hourMax) : 0;
      } else {
        newValues.estimated_time_min = 0;
        newValues.estimated_time = 0;
      }

      delete newValues.estimated_time_range;
    }

    if ('responsibles' in newValues) {
      newValues.responsibles = newValues.responsibles && [newValues.responsibles];
    }

    newValues.ref_token = localRandomUuid;

    return onCustomizeDataApi ? onCustomizeDataApi(newValues) : newValues;
  };

  const onCreateHandler = async () => {
    try {
      setSaving(true);

      const values = normalizeFormValues(await form.validateFields());

      if (createToParentTask) {
        values.parent_id = createToParentTask.uuid;
      }

      values.recurrence_type = values.recurrence_type ?? 'none';

      values.uploads = filesData.filter((item) => item.response?.uuid).map((item) => item.response?.uuid);

      let description = descriptionTextRef.current?.getValue();

      if (description) {
        description = description.replaceAll(
          `src="${ENVIRONMENT.REACT_APP_UPLOADS_PATH.replace('uploads', 'tmp')}/`,
          `src="${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/`,
        );

        descriptionTextRef.current?.updateValue(description);

        values.description_delta = descriptionTextRef.current?.getYjs();
      }

      if (isPersonal) {
        values.responsibles = [user.uuid];
      }

      const response = await apiRequests.post(`${apiRoutes.PROJECT_TASKS}`, values);

      onSaved?.(response.data.data);

      history.push(`?task_uuid=${response.data.data.uuid}${isPersonal ? '&task_personal' : ''}`);

      return response;
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }

    return null;
  };

  const onUpdateHandler = async (values: any, abort?: AbortController) => {
    setSaving(true);

    try {
      const response = await apiRequests.put(
        `${apiRoutes.PROJECT_TASKS}/${uuid ?? values?.uuid}`,
        normalizeFormValues(values),
        {},
        abort,
      );

      onSaved?.(response.data.data);
      setSaving(false);

      return response;
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        asyncErrorHandler(error);
        setSaving(false);
      }
    }

    return null;
  };

  const onButtonUpdateHandler = async () => {
    const response = await onUpdateHandler(await form.validateFields());

    if (response) {
      setData(response.data.data);
    }
  };

  const onFieldsChangeHandler: FormProps['onFieldsChange'] = (fields) => {
    if (!state?.recurrence && !isCreateModal && (fields.length !== 1 || !['title'].includes((fields[0].name as any)[0]))) {
      setHasDirtyFields(hasDirtyFields + 1);
    }
  };

  const onFieldsChangeDebounce = () => {
    debounceCallback(() => onFieldsChangeHandler([], []));
  };

  const updateDirtyFields = () => {
    setHasDirtyFields(0);

    if (form.getFieldsError().find((field) => field.errors.length)) {
      return;
    }

    const getDirtyFields = () => {
      const values = form.getFieldsValue();
      const newValues: any = {};

      Object.keys(values).forEach((key) => {
        if (!isEqual(values[key], initialValues[key])) {
          newValues[key] = values[key];
        }
      });

      if ('followers' in newValues || 'responsibles' in newValues) {
        if (!('followers' in newValues)) {
          newValues.followers = values.followers;
        }

        if (!('responsibles' in newValues)) {
          newValues.responsibles = values.responsibles;
        }
      }

      return newValues;
    };

    const dirtyValues = getDirtyFields();

    if (Object.keys(dirtyValues).length) {
      onUpdateHandler(dirtyValues).then((response) => {
        if (response) {
          setData(response.data.data);
        }
      });
    }
  };

  const fetchTask = async () => {
    setFetching(true);

    try {
      const { data } = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${uuid}`);

      setData(data.data);
    } catch (error) {
      setFetchTaskError(error);
    } finally {
      setFetching(false);
    }
  };

  const fetchParentTask = async () => {
    setFetchingParent(true);

    try {
      const { data } = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${createToParentUuid}`);

      setCreateToParentTask(data.data);
      setProject(data.data.project);
    } catch (error) {
      setFetchTaskError(error);
    } finally {
      setFetchingParent(false);
    }
  };

  const fetchCopyTask = async () => {
    setFetching(true);

    try {
      const { data } = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${createFromTaskUuid}`);

      delete data.data.recurrence_type;
      delete data.data.recurrence_limit;
      delete data.data.uuid;

      setData(data.data);
    } catch (error) {
      setFetchTaskError(error);
    } finally {
      setFetching(false);
    }
  };

  const fetchPersonalProject = async () => {
    setFetching(true);

    try {
      const res = await apiRequests.get(`${apiRoutes.PROJECTS}?personal_only=true`);
      setProject(res?.data?.data?.[0]);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setFetching(false);
    }
  };

  const onUpdateParentTask = (parentTask: any) => {
    setState((prev: any) => ({ ...prev, parent: parentTask }));

    onUpdateHandler({ parent_id: parentTask?.uuid ?? null });
  };

  if (!mountedRef.current) {
    mountedRef.current = true;

    if (!isCreateModal && uuid) {
      fetchTask();
    }

    if (isCreateModal) {
      if (createFromTaskUuid) {
        fetchCopyTask();
      }

      if (createToParentUuid) {
        fetchParentTask();
      }

      if (!createFromTaskUuid && isPersonal) {
        fetchPersonalProject();
      }
    }
  }

  useEffect(() => {
    const onNetworkOnline = async () => {
      if (!uuid) return;

      try {
        const response = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${uuid}/description-delta`);

        setState((old: any) => ({ ...old, description_delta: response.data.data }));
      } catch (error) {
        asyncErrorHandler(error);
      }
    };

    window.addEventListener('online', onNetworkOnline);

    return () => {
      window.removeEventListener('online', onNetworkOnline);
    };
  }, [uuid]);

  useEffect(() => {
    if (hasDirtyFields <= 0) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      updateDirtyFields();
    }, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [hasDirtyFields]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.task',
      callback: async (event: any) => {
        if (event.action !== 'update' || event.id !== uuid || fetching || event.payload.ref_token === localRandomUuid) return;

        const { data } = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${uuid}`);

        const values = form.getFieldsValue();

        const dirtyKeys = Object.keys(initialValues).filter((key) => !isEqual(values[key], initialValues[key]));

        setData(data.data, true, dirtyKeys);
      },
    },
  });

  return {
    user,
    form,
    filesData,
    setFilesData,
    state,
    setState,
    setData,
    initialValues,
    fetching,
    fetchingParent,
    fetchTaskError,
    setFetching,
    selectedProject,
    setProject,
    saving,
    setSaving,
    fetchTask,
    createToParentTask,
    scrollRef,
    isCreateModal,
    connectedUsers,
    setConnectedUsers,
    editorStatus,
    setEditorStatus,
    updateDirtyFields,
    onCreateHandler,
    onUpdateHandler,
    onUpdateParentTask,
    onButtonUpdateHandler,
    onFieldsChangeHandler,
    onFieldsChangeDebounce,
    scrollToBottom,
    descriptionTextRef,
  };
};

export default useTaskModal;
