import { Input, InputProps } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { convertHrsMinsToMins, convertMinsToHrsMins } from 'utils/string.utils';

interface TimeRangeCustomProps {
  value?: string;
  disabled?: boolean;
  onChange?: (value?: string) => void;
  onBlur?: InputProps['onBlur'];
}

const convertFractionToHour = (value?: string) => {
  if (!value) return '00:00 - 00:00';

  let [fractionMin, fractionMax] = value.split('/');

  if (!fractionMax) {
    fractionMax = fractionMin;
    fractionMin = '0';
  }

  const minutesMin = fractionMin ? (Math.floor(parseFloat(fractionMin) * 100) / 100) * 60 : 0;

  const minutesMax = fractionMax ? (Math.floor(parseFloat(fractionMax) * 100) / 100) * 60 : 0;

  return `${convertMinsToHrsMins(minutesMin)} - ${convertMinsToHrsMins(minutesMax)}`;
};

const convertHourToFraction = (value?: string) => {
  if (!value) return value;

  const [hourMin, hourMax] = value.split('-');

  const minutesMin = hourMin ? convertHrsMinsToMins(hourMin) : 0;

  const minutesMax = hourMax ? convertHrsMinsToMins(hourMax) : 0;

  if (!minutesMin && !minutesMax) {
    return '';
  }

  return `${Math.floor((minutesMin / 60) * 100) / 100}/${Math.floor((minutesMax / 60) * 100) / 100}`;
};

const TimeRangeCustom: FC<TimeRangeCustomProps> = ({ value, disabled, onChange, onBlur }) => {
  const [updating, setUpdating] = useState(false);
  const [fractionValue, setFractionValue] = useState(() => {
    return convertHourToFraction(value);
  });

  return (
    <Input
      value={updating ? fractionValue : value}
      placeholder="ex: 1/2 = 1h - 2h"
      suffix={<ClockCircleOutlined className="suffix-svg" />}
      disabled={disabled}
      onChange={(event) => {
        const valueNumbers = event.target.value.replace(/[^0-9./]/g, '');
        setFractionValue(valueNumbers);
      }}
      onFocus={() => {
        setFractionValue(convertHourToFraction(value));
        setUpdating(true);
      }}
      onBlur={(event) => {
        onChange?.(convertFractionToHour(fractionValue));
        setUpdating(false);
        onBlur?.(event);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
          event.preventDefault();
          setFractionValue(convertHourToFraction(value));
          const input = event.currentTarget;
          setTimeout(() => input.blur());
        }
        if (event.key === 'Enter') {
          event.currentTarget.blur();
        }
      }}
    />
  );
};

export default TimeRangeCustom;
