import { FC } from 'react';
import { Card, Col, Row, Tooltip } from 'antd';
import { CommentTemplate } from '../../types';
import TemplateCommentCard from './TemplateCommentCard';
import templates from './templates.config';

const CardTooltip = () => {
  return (
    <Card title="Templates available:" size="small" bodyStyle={{ overflowY: 'auto', maxHeight: 350 }}>
      {templates.map((item) => (
        <div key={item.uuid}>
          {item.uuid}. {item.title}
        </div>
      ))}
    </Card>
  );
};

interface TemplateCommentSearchProps {
  onTemplateClick?: (template: CommentTemplate) => void;
}

const TemplateCommentContainer: FC<TemplateCommentSearchProps> = ({ onTemplateClick }) => {
  return (
    <div style={{ width: '100%', display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
      <div style={{ marginBottom: '12px' }}>
        <Tooltip
          title={<CardTooltip />}
          overlayStyle={{ maxWidth: 320 }}
          overlayInnerStyle={{ padding: 0, background: 'transparent' }}
        >
          Templates:{' '}
        </Tooltip>
      </div>

      <Row
        wrap={false}
        gutter={[8, 0]}
        className="app-scrollbar"
        style={{ flex: 1, paddingBottom: 4, marginBottom: 6, overflow: 'auto' }}
      >
        {templates.map((template) => (
          <Col key={template.uuid} flex="1">
            <TemplateCommentCard template={template} onClick={onTemplateClick} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TemplateCommentContainer;
