import type { UserResponse } from '../types';
import { PermissionKey, PERMISSION_KEYS } from '../config/permissions';

export const checkHasPermission = (user: UserResponse | null, key: PermissionKey | undefined): boolean => {
  if (!key || !user) return false;

  const permission = PERMISSION_KEYS[key];

  return !!user.permissions.find((item) => item.name === permission);
};

export const checkHasRole = (user: UserResponse | null, type: string | undefined): boolean => {
  if (!type || !user) return false;

  return !!user.roles.find((item) => item.name === type);
};

export const checkIsAdmin = (user: UserResponse | null) => {
  return user?.contact?.type === 'regular_user';
};

// Auth token
export const getToken = () => {
  return localStorage ? localStorage.getItem('auth_token') ?? null : null;
};

export const saveToken = (token: string) => {
  if (localStorage) {
    localStorage.setItem('auth_token', token);
  }
};

export const removeToken = () => {
  if (localStorage) {
    localStorage.removeItem('auth_token');
  }
};

export const logoutUser = () => {
  removeToken();
  window.location.href = '/login';
};
