import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWeeklyLogs } from 'store/container/weeklyLogs/weeklyLogs-slice';
import { UserResponse } from 'types';
import { RootState } from 'store';

const useWeeklyTimer = () => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);
  const globalLog = useSelector((globalState: RootState) => globalState.globalLog);
  const weeklyLogs = useSelector((globalState: RootState) => globalState.weeklyLogs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWeeklyLogs());
  }, [user.uuid, globalLog.trigger, dispatch]);

  return { weeklyLogs };
};

export default useWeeklyTimer;
