import { FC, ReactNode, useState } from 'react';
import { Button, ButtonProps } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import asyncErrorHandler from 'utils/asyncErrorHandler';

interface DownloadAssetProps {
  uuid: string;
  name?: string;
  icon?: ReactNode;
  size?: ButtonProps['size'];
}

const DownloadAsset: FC<DownloadAssetProps> = ({ uuid, name, icon, size }) => {
  const [downloading, setDownloading] = useState(false);

  const onClickHandler = () => {
    setDownloading(true);

    apiRequests
      .get(`${apiRoutes.UPLOADS}/${uuid}/download`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        if (name) {
          link.download = name;
        }
        link.click();
      })
      .catch((error) => {
        asyncErrorHandler(error);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <Button type="link" size={size} onClick={onClickHandler} icon={icon ?? <CloudDownloadOutlined />} loading={downloading} />
  );
};

export default DownloadAsset;
