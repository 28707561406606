/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { PushTokensResponse, UserResponse } from 'types';
import { setHeaderAuth, setHeaderVersion } from 'utils/api';
import { getToken, removeToken, saveToken } from 'utils/auth';
// import { getToken } from 'firebase/messaging';

export interface AuthStateType {
  loading: boolean;
  loginLoading: boolean;
  loginError: string | null;
  user: UserResponse | null;
}

const initialToken = getToken();

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: !!initialToken,
    loginLoading: false,
    loginError: null,
    user: null,
    token: initialToken,
  } as AuthStateType,
  reducers: {
    login: (state, action: { payload: { data: any; onLoaded?: () => void }; type: string }) => {},
    loadUser: (state, action: { payload: { onLoaded?: () => void } | undefined; type: string }) => {},
    logout: (state, action: { payload: { onLoaded?: () => void } | undefined; type: string }) => {
      removeToken();
      setHeaderAuth(null);

      return {
        loading: false,
        loginLoading: false,
        user: null,
        token: null,
        loginError: null,
      };
    },
    setLoginLoading: (state, action) => {
      if (action.payload === state.loginLoading) {
        return state;
      }

      return {
        ...state,
        loginLoading: action.payload,
        loginError: null,
      };
    },
    setAuthLoading: (state, action) => {
      if (action.payload === state.loading) {
        return state;
      }

      return {
        ...state,
        loading: action.payload,
      };
    },
    setLoginError: (state, action: { payload: string | null; type: string }) => {
      return {
        ...state,
        loginLoading: false,
        loginError: action.payload,
      };
    },
    setAuthData: (state, action: { payload: UserResponse; type: string }) => {
      saveToken(action.payload.auth_token);
      setHeaderVersion(action.payload.api_version);
      setHeaderAuth(action.payload.auth_token);

      return {
        ...state,
        loading: false,
        loginLoading: false,
        loginError: null,
        user: action.payload,
      };
    },
    updateAuthData: (state, action: { payload: UserResponse | null; type: string }) => {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    },
    registerPushToken: (state, action: { payload: string; type: string }) => {},
    updatePushToken: (state, action: { payload: PushTokensResponse[]; type: string }) => {
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          push_tokens: action.payload,
        },
      };
    },
  },
});

export const {
  setAuthData,
  setAuthLoading,
  setLoginLoading,
  login,
  logout,
  loadUser,
  updateAuthData,
  registerPushToken,
  updatePushToken,
  setLoginError,
} = authSlice.actions;

export default authSlice.reducer;
