import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Popconfirm, Row, Space, Tag } from 'antd';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import CustomTable from 'components/CustomTable';
import UserAvatars from 'components/UserAvatars';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { convertSnakeCaseToTitleCase, getStatusColor, parseDateToUSFormat } from 'utils/string.utils';
import AddRelationship from '../AddRelationShip';

interface SubTasksListProps {
  task: Record<string, any>;
  onTaskUpdate: () => Promise<void>;
}

const SubTasksList: FC<SubTasksListProps> = ({ task, onTaskUpdate }) => {
  const history = useHistory();
  const [edit, setEdit] = useState(false);

  const onRemoveParentTask = async (uuid: string) => {
    try {
      await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${uuid}`, {
        parent_id: null,
      });

      await onTaskUpdate();
    } catch (error) {
      asyncErrorHandler(error);
    }
  };

  return (
    <div>
      <Row className="mb-m" align="top" justify="space-between">
        <div />

        <Space>
          {!edit && <Button onClick={() => setEdit(!edit)}>Add relationship</Button>}

          <Link to={`?task_uuid=create&task_parent_id=${task.uuid}`} className="ant-btn ant-btn-primary">
            + Add
          </Link>
        </Space>
      </Row>

      <AddRelationship task={task} edit={edit} setEdit={setEdit} onUpdate={onTaskUpdate} />

      <CustomTable
        className="table__mini"
        hidePagination
        hideActionBar
        hideRowSelection
        hideActionButton
        onDoubleClick={(row: any) => {
          history.push(`?task_uuid=${row?.uuid}`);
        }}
        columns={[
          {
            title: 'Status',
            dataIndex: 'status',
            render: (value: any) => <Tag color={getStatusColor(value)}>{convertSnakeCaseToTitleCase(value)}</Tag>,
          },
          {
            title: 'Request',
            dataIndex: 'title',
          },
          {
            title: 'Due date',
            dataIndex: 'end_date',
            minSpace: true,
            minSpaceText: (value: any) => (value ? parseDateToUSFormat(value) : '-'),
            render: (value: any) => (value ? parseDateToUSFormat(value) : '-'),
          },
          {
            title: 'Assignees',
            dataIndex: 'related_users',
            exportRenderTheme: 'users',
            width: 150,
            render: (value: any) => <UserAvatars users={value} filterType="responsible" />,
          },
          {
            title: 'Followers',
            dataIndex: 'related_users',
            exportRenderTheme: 'users',
            width: 150,
            render: (value: any) => <UserAvatars users={value} filterType="follower" />,
          },
          {
            title: '',
            dataIndex: '',
            fixed: 'right',
            render: (_: any, row: any) => (
              <Space size={20}>
                <EditOutlined
                  onClick={() => {
                    history.push(`?task_uuid=${row.uuid}`);
                  }}
                  className="primary pointer"
                />
                <Popconfirm title="Do you want to remove this from subtasks?" onConfirm={() => onRemoveParentTask(row.uuid)}>
                  <CloseCircleOutlined className="red pointer" />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        data={task.subtasks}
        tableKey={`sub-tasks-table-${task.uuid}`}
      />
    </div>
  );
};

export default SubTasksList;
