/* eslint-disable prefer-template */
/* eslint-disable no-multi-assign */
/* eslint-disable one-var */
/* eslint-disable no-bitwise */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-cond-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import { randomArrayItem } from './array.utils';

const locale = 'en-us';

// @ts-ignore
export const localRandomUuid: string = window.crypto.randomUUID();

/**
 * Converts the input string to Snake Case
 * ex:
 * input : 'User Good'
 * output : 'user_good'
 * @param {string} string
 * @returns {string}
 */
export const convertToSnakeCase = (string: string): string => {
  if (!string) {
    return '';
  }
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
};

export const convertSnakeCaseToTitleCase = (string: string, capitalize: 'first-letter' | boolean = true): string => {
  if (!string) {
    return '';
  }

  const text = string.replace(/_/g, ' ');

  if (!capitalize) {
    return text;
  }

  if (capitalize === 'first-letter') {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts the input number with commas
 * ex:
 * input : '12232'
 * output : '12,232'
 * @param {number} x
 * @returns {string}
 */
export const numberWithCommas = (x: any): string => x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * coverts number to US Dollar
 * ex:
 * input : '12232'
 * output : '12,232'
 * @param {number} x
 * @returns {string}
 */
export const priceFormatter = (x: any): string => {
  return x || x === 0 || x === '0' ? `$${numberWithCommas(Number.parseFloat(`${x}`)?.toFixed(2))}` : x;
};

/**
 * Returns the appropriate color for the given status
 * @param {string} status
 * @returns {string}
 */
export const getStatusColor = (status: string): string => {
  const greenStatus = ['completed', 'online', 'success', 'paid', 'processed', 'sent', 'active', 'published', 'succeeded'];
  const yellowStatus = ['approved', 'draft'];
  const redStatus = ['needs_attention', 'failed', 'fail', 'high'];
  const purpleStatus = ['todo'];
  const cyanStatus = ['in_progress', 'scheduled'];
  const blueStatus = ['working_on_now'];
  const magentaStatus = ['waiting_approval', 'waiting_to_launch'];
  const goldStatus = ['pending', 'paused', 'to_review', 'medium'];
  const greyStatus = ['cancelled', 'inactive', 'deleted'];

  let color = 'blue';

  if (greyStatus.find((el) => el === status)) {
    color = 'grey';
  }

  if (purpleStatus.find((el) => el === status)) {
    color = 'purple';
  }
  if (cyanStatus.find((el) => el === status)) {
    color = 'cyan';
  }
  if (blueStatus.find((el) => el === status)) {
    color = 'blue';
  }
  if (magentaStatus.find((el) => el === status)) {
    color = 'magenta';
  }
  if (goldStatus.find((el) => el === status)) {
    color = 'gold';
  }
  if (greenStatus.find((el) => el === status)) {
    color = 'green';
  }
  if (yellowStatus.find((el) => el === status)) {
    color = 'yellow';
  } else if (redStatus.find((el) => el === status)) {
    color = 'red';
  }
  return color;
};

export const getAntdColorHex = (color: string) => {
  const colorMap: any = {
    magenta: '#ffadd2',
    blue: '#91caff',
    grey: '#808080',
    purple: '#d3adf7',
    cyan: '#87e8de',
    gold: '#ffe58f',
    yellow: '#ffe58f',
    green: '#b7eb8f',
    red: '#ffa39e',
    volcano: '#ffbb96',
    orange: '#ffd591',
  };

  return colorMap[color];
};

/**
 * Returns the date difference from two give dates.
 * if the to is not defined it will default to current date
 * @param {string} from
 * @param {string} to
 * @returns {number}
 */
export const getDateDiff = (from: string, to?: string): number => {
  if (!from) return 0;
  const date1: any = new Date(from.replaceAll('-', '/'));
  const date2: any = to ? new Date(to.replaceAll('-', '/')) : Date.now();
  const diffTime = Math.abs(date1 - date2);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

/**
 * Converts the HTML List to string.
 * @param {string} data
 * @returns {string}
 */
export const convertHtmlListToText = (data: string): string => {
  data = data.replace('<ul>', '');
  data = data.replace('</ul>', '');
  data = data.replace('<li>', '');
  data = data.replaceAll('</li>', '');
  data = data.replaceAll('<li>', '\n');
  return data;
};

/**
 * return the month name for the give month in number
 * @param {number} monthNum
 * @returns {string}
 */
export const getMonthNameFromNumber = (value: number): string => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  return months[value - 1];
};

/**
 * coverts the Text to HTML List
 * @param {string} data
 * @returns {string}
 */
export const convertTextToHtmlList = (data: string) => {
  const lines = data.split('\n');
  let html = '<ul>';
  lines.forEach((line) => {
    html += `<li>${line}</li>`;
  });
  html += '</ul>';
  return html;
};

/**
 * Returns the random ANTD color
 * @returns {string}
 */
export const getRandomAntdTagColor = () => randomArrayItem(['cyan', 'purple', 'orange', 'geekblue', 'lime', 'magenta']);

export const parseDateToMMMDD = (date: any, utc = false) => {
  if (!date) {
    return undefined;
  }

  const dateObj = utc ? dayjs.utc(date) : dayjs(date);

  return dateObj.format(dateObj.isSame(dayjs(), 'year') ? 'MMM DD[th]' : 'MMM DD[th], YY');
};

/**
 * Converts any date format to US Date Format
 */
export const parseDateToUSFormat = (date: any, time = false, utc = false) => {
  if (!date) {
    return '-';
  }

  return (utc ? dayjs.utc(date) : dayjs(date)).format(`MM/DD/YYYY${time ? ' - hh:mm A' : ''}`);
};

export const parseDateNumberToTimeFormat = (days: number, hours: number, minutes: number) => {
  const totalHours = days * 24 + hours;

  return `${totalHours < 10 ? '0' : ''}${totalHours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

export const getAvatar = (user?: any) => {
  if (!user) {
    return null;
  }

  const nameArr = user?.name?.split(' ') ?? [];
  let name = '';
  const avatar = user?.avatar;

  if (avatar)
    return {
      type: 'img',
      value: avatar,
    };

  nameArr.map((val: string) => {
    name += val.charAt(0);
    return val;
  });
  return {
    type: 'text',
    value: name,
  };
};

export const getMentionUuidFromNode = (markup: string): string[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(markup, 'text/html');
  const uuids: string[] = [];

  doc.querySelectorAll<HTMLSpanElement>('span.mention').forEach((item) => {
    if (item.innerText.trim() && item.dataset.id) {
      uuids.push(item.dataset.id as string);
    }
  });

  return uuids;
};

/**
 * Parses the comment
 * @param {string} comment
 * @returns {string}
 */
export const mentionCommentParser = (comment: string): string => {
  const regex = /@\[(.*?)\]\(.*?\)/g;
  return comment.replaceAll(regex ?? '', '<span>@$1</span>');
};

/**
 * Check if the given input is valid UUID
 * @param {string} uuid
 * @returns {boolean}
 */
export const checkIfValidUUID = (uuid: string): boolean => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(uuid);
};

/**
 * Formats the give number to USD CURRENCY
 * @param {string} value
 * @returns {string}
 */
export const currencyFormatter = (value: any): string => {
  const val = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  }).format(value);
  return val;
};

/**
 * Returns difference between two dates
 * @param {string} startDate
 * @param {string} endDate
 * @returns {number}
 */
export const getDateDifferenceInMinutes = (startDate: string, endDate = new Date(Date.now())): number => {
  return dayjs(endDate).diff(startDate) / 60000;
};

/**
 * Return the date difference in H:i format
 * @param {string} startDate
 * @param {string} endDate
 * @returns {string}
 */
export const getDateDifferenceInHrsMins = (startDate: string, endDate = new Date(Date.now())): string =>
  convertMinsToHrsMins(getDateDifferenceInMinutes(startDate, endDate));

/**
 * Converts to mins to H:i format
 * @param {number} mins
 * @returns {string}
 */
export const convertMinsToHrsMins = (mins: number): string => {
  const h = Math.floor(mins / 60);
  const m = Math.floor(mins % 60);
  const hStr = h < 10 ? `0${h}` : h; // (or alternatively) h = String(h).padStart(2, '0')
  const mStr = m < 10 ? `0${m}` : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${hStr}:${mStr}`;
};

/**
 * Converts Days Hours Mins to Mins
 * @param {number} d
 * @param {number} h
 * @param {number} m
 * @returns {number}
 */
export const convertDaysHrsMinsToMins = (d: number, h: number, m: number): number => d * 24 * 60 + h * 60 + m;

/**
 * Convert HH:MM to Mins
 * @param time {string}
 * @returns {number}
 */
export const convertHrsMinsToMins = (time: string): number => {
  const values = time.split(':');
  return parseInt(values[0], 10) * 60 + parseInt(values[1], 10);
};

/**
 * Returns offset timestamp
 * @param {string} mins
 * @returns {Date}
 */
export const getOffsetTimestamp = (mins: number): Date => {
  const offsetTime = new Date();
  offsetTime.setMinutes(offsetTime.getMinutes() + mins);

  return offsetTime;
};

export const convertToHHMM = (time: string): string => {
  if (!time) return '0h0m';
  return `${Number(time.split(':')[0])}h${time.split(':')[1]}`;
};

export const convertHHMMToMinutes = (hhMM: string): number => {
  if (!hhMM) return 0;

  const [hour, minute] = hhMM.split('h');

  return parseFloat(hour ?? 0) * 60 + parseFloat(minute ?? 0);
};

/**
 * Return the query string value from the URL
 * @param {string} search
 * @param {string} key
 * @returns {string}
 */
export const getQueryValue = (search: string, key: string) => {
  const query = new URLSearchParams(search);
  return query.get(key);
};

export const ellipseText = (text: string | undefined | null, charsLimit = 50): string => {
  return text && text?.length > charsLimit ? `${text.substring(0, charsLimit)}...` : text ?? '';
};

export const convertArrToCommaSeparatedString = (array: any[]): string => {
  let finalString = '';

  array?.forEach((el: any, i: any) => {
    finalString += `${el}${i === array?.length - 1 ? '' : ', '}`;
  });

  return finalString;
};
export const minCharSpan = (text: string | any, minChars: number) => {
  if (!text) {
    text = '';
  }
  text = text?.toString();

  let finalText = '';

  const reqSpace = minChars - text?.length;

  if (reqSpace > 0) {
    Array(reqSpace)
      .fill(0)
      .forEach(() => {
        finalText += '&nbsp;';
      });
  }

  return finalText;
};
export const getHtmlFromArray = (value: any[]) => {
  let html = '<div>';
  value?.forEach((el) => {
    html += el;
  });
  html += '</div>';

  return html;
};

export const convertToSlug = (Text: string) => {
  if (!Text) return '';
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};
export const trimUrl = (url: string | undefined) => {
  if (!url) return null;

  if (url.slice(-1) === '/') {
    url = url.slice(0, -1);
  }

  return url;
};

export const checkIsEmail = (email: any) => {
  if (!email) return false;
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const hRange = [0, 360];
const sRange = [90, 100];
const lRange = [30, 50];

export const generateHexColor = (name: string) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return hslToHex(h, s, l);
};

function hslToHex(h: any, s: any, l: any) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: any, q: any, t: any) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x: any) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const isSameDay = (d1: Date, d2: Date) => {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const urlify = (text = '') => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text?.replace(urlRegex, (url) => {
    return '<a target="blank" href="' + url + '">' + url + '</a>';
  });
};

export const includesInObject = (value: string, object: any) => {
  if (!object || !value) {
    return false;
  }

  let hasSearch = false;

  Object.keys(object).forEach((key: string) => {
    if (typeof object[key] === 'string') {
      if (object[key]?.toLowerCase().includes(value.toLowerCase())) {
        hasSearch = true;
      }
    } else if (typeof object[key] === 'object') {
      return includesInObject(value, object[key]);
    }
  });

  return hasSearch;
};

export const parseProjectTitle = (project: any) => {
  if (project?.kind === 'personal' || project?.project_kind === 'personal') {
    return project?.project_title ?? project?.title;
  }

  if (project?.project_title) {
    return `${project?.customer_abbreviation ? `${project?.customer_abbreviation} - ` : ''}${project?.project_title}`;
  }

  return `${project?.customer?.abbreviation ? `${project?.customer?.abbreviation} - ` : ''}${project?.title}`;
};

export const parseTaskTitle = (task: any) => {
  if (task?.project?.kind === 'personal' || task?.project_kind === 'personal') {
    return task?.task_title ?? task?.title;
  }

  return `${task?.customer_abbreviation ? `${task?.customer_abbreviation} - ` : ''}${task?.task_title}`;
};

export const removeImage = (html: string | null | undefined, url: string) => {
  if (!html) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  doc.querySelector(`img[src="${url}"]`)?.parentElement?.remove();

  return doc.body.innerHTML;
};

export const checkExternalUrl = (urlString: string) => {
  const url = new URL(urlString);

  return window.location.hostname !== url.hostname;
};

export const getNameAbbrev = (fullName: string) => {
  const names = fullName.split(' ');
  return names.length > 1 ? `${names[0]} ${names[1].substring(0, 1)}.` : fullName;
};
