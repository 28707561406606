import { ReactNode } from 'react';
import { TfiHome } from 'react-icons/tfi';
import { LuCalendarClock, LuUsers } from 'react-icons/lu';
import { CiFolderOn, CiMail, CiMedicalClipboard } from 'react-icons/ci';
import { TbSubtask } from 'react-icons/tb';
import { IoSettingsOutline } from 'react-icons/io5';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import appRoutes from 'config/appRoutes';
import { PermissionKey } from 'config/permissions';

interface MenuItemBaseType {
  label: string;
  permission?: PermissionKey;
  role?: string;
  needsSuperUser?: boolean;
  type?: 'regular_user' | 'contact' | 'freelancer';
}

export interface MenuItemBaseLinkType extends MenuItemBaseType {
  path: string;
}

export interface MenuItemLinkType extends MenuItemBaseLinkType {
  icon: ReactNode;
}

export interface MenuItemParentType extends MenuItemBaseType {
  icon: ReactNode;
  submenu: MenuItemBaseLinkType[];
  key: string;
}

export type MenuItemType = MenuItemLinkType | MenuItemParentType;

export const regularUserConfig: MenuItemType[] = [
  {
    label: 'Dashboard',
    path: appRoutes.DASHBOARD,
    icon: <TfiHome />,
    permission: 'DASHBOARD_READ',
  },
  {
    label: 'Personal',
    path: appRoutes.PERSONAL_TASKS,
    icon: <CiMedicalClipboard />,
    permission: 'TASKS_READ',
    type: 'regular_user',
  },
  {
    label: 'Inbox',
    path: appRoutes.INBOX,
    icon: <CiMail />,
    permission: 'COMMENTS_READ',
  },
  {
    label: 'Tasks',
    path: appRoutes.TASKS,
    icon: <TbSubtask strokeWidth="1" />,
    permission: 'TASKS_READ',
  },

  {
    label: 'Projects',
    path: appRoutes.PROJECTS,
    icon: <CiFolderOn />,
    permission: 'PROJECTS_READ',
    type: 'regular_user',
  },
  {
    label: 'Timelog',
    path: appRoutes.TIME_LOG_REPORT,
    icon: <LuCalendarClock strokeWidth="1" />,
    permission: 'TIMELOG_READ',
  },
  {
    label: 'Clients',
    path: appRoutes.CLIENTS,
    icon: <LuUsers strokeWidth="1" />,
    permission: 'CUSTOMERS_READ',
    type: 'regular_user',
  },
  {
    label: 'Requests',
    path: appRoutes.REQUESTS,
    icon: <HiOutlineDocumentDownload strokeWidth="1" />,
    permission: 'REQUEST_READ',
  },
  {
    key: 'admin',
    label: 'Admin',
    icon: <IoSettingsOutline />,
    type: 'regular_user',
    submenu: [
      {
        label: 'Users',
        path: appRoutes.CONTACTS,
        permission: 'CONTACTS_READ',
      },
      {
        label: 'Forms',
        path: appRoutes.FORMS,
        permission: 'FORM_READ',
      },
      {
        label: 'Logs',
        path: appRoutes.LOGS,
        permission: 'EMAIL_LOG_READ',
      },
      {
        label: 'Time report',
        path: appRoutes.PROJECT_TiME_REPORT,
        permission: 'PROJECTS_READ',
      },
      {
        label: 'Activity log',
        path: appRoutes.ACTIVITY_LOG,
        permission: 'PROJECTS_READ',
      },
      {
        label: 'Company info',
        path: appRoutes.ADMINISTRATION,
        permission: 'INTEGRATIONS_READ',
        needsSuperUser: true,
      },
      {
        label: 'Resources',
        path: appRoutes.CMS,
        permission: 'CMS_READ',
      },
      {
        label: 'Content',
        path: appRoutes.CONTENT,
        permission: 'POST_READ',
      },
      {
        label: 'Item types',
        path: appRoutes.PRODUCT_TYPES,
        permission: 'ITEM_TYPE_READ',
        needsSuperUser: true,
      },
      {
        label: 'Groups',
        path: appRoutes.GROUPS,
        permission: 'GROUPS_READ',
        needsSuperUser: true,
      },
      {
        label: 'Roles',
        path: appRoutes.ROLES,
        permission: 'ROLES_READ',
        needsSuperUser: true,
      },
      {
        label: 'Integrations',
        path: appRoutes.INTEGRATIONS,
        permission: 'INTEGRATIONS_READ',
      },
      {
        label: 'My calendar',
        path: appRoutes.CALENDAR,
        permission: 'INTEGRATIONS_READ',
      },
    ],
  },
];

const allRoutes: MenuItemBaseLinkType[] = [];

regularUserConfig.forEach((el) => {
  if ('submenu' in el) {
    el.submenu.forEach((sub) => {
      allRoutes.push(sub);
    });
  } else {
    allRoutes.push(el);
  }
});

export const getRouteConfig = () => {
  return regularUserConfig;
};

export const getAllRoutes = () => {
  return allRoutes;
};
