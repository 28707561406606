import { Suspense, useRef } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import AuthenticationRoute from 'components/AuthenticationRoute';
import NotFound404 from 'auth/404';
import appRoutes from 'config/appRoutes';
import { loadUser } from 'store/container/auth/auth-slice';
import { setVersionUpdate } from 'store/container/versionUpdate/versionUpdate-slice';
import { lazyRetry } from 'utils/lazyRetry';
import { setSentryUser } from '../sentry';
import { RootState } from '../store';
import useWebSocket from '../utils/useWebSocket';
import AuthClient from '../auth/AuthClient';
import 'styles/global.less';

const Roles = lazyRetry(() => import('modules/role/routes'));
const Groups = lazyRetry(() => import('modules/groups/routes'));
const Logs = lazyRetry(() => import('modules/log/routes'));
const Clients = lazyRetry(() => import('modules/client/routes'));
const Dashboard = lazyRetry(() => import('modules/dashboard/routes'));
const Contacts = lazyRetry(() => import('modules/contact/routes'));
const Forms = lazyRetry(() => import('modules/forms/routes'));
const Customers = lazyRetry(() => import('modules/customer/routes'));
const CMS = lazyRetry(() => import('modules/cms/routes'));
const Requests = lazyRetry(() => import('modules/request/routes'));
const ProductTypes = lazyRetry(() => import('modules/product/views/type'));
const Projects = lazyRetry(() => import('modules/project/routes'));
const Advertising = lazyRetry(() => import('modules/advertising/routes'));
const Analytics = lazyRetry(() => import('modules/analytics/routes'));
const Inbox = lazyRetry(() => import('modules/inbox/routes'));
const Tasks = lazyRetry(() => import('modules/tasks/routes'));
const Content = lazyRetry(() => import('modules/content/routes'));
const TimelogReport = lazyRetry(() => import('modules/timelogReport/routes'));
const PersonalTasks = lazyRetry(() => import('modules/personal-tasks/routes'));
const Integrations = lazyRetry(() => import('modules/integrations/routes'));
const ActivityLog = lazyRetry(() => import('modules/activity-log/routes'));
const Administration = lazyRetry(() => import('modules/administration/routes'));
const GoogleOAuth = lazyRetry(() => import('modules/GoogleOAuth/routes'));
const Calendar = lazyRetry(() => import('modules/calendar/routes'));
const ProjectTimeReport = lazyRetry(() => import('modules/projectTimeReport/routes'));
const CommentTemplate = lazyRetry(() => import('modules/comment-templates/routes'));
const FileViewer = lazyRetry(() => import('modules/fileViewer/routes'));

const Login = lazyRetry(() => import('auth/Login'));
const Register = lazyRetry(() => import('auth/Register'));
const ForgotPassword = lazyRetry(() => import('auth/ForgotPassword'));
const ResetPassword = lazyRetry(() => import('auth/ResetPassword'));
const PrivacyPolicy = lazyRetry(() => import('app/PrivacyPolicy'));
const TermsOfServices = lazyRetry(() => import('app/TermsOfServices'));

const Routing = () => {
  const auth = useSelector((store: RootState) => store.auth);
  const isMountedRef = useRef(false);
  const dispatch = useDispatch();

  if (!isMountedRef.current) {
    isMountedRef.current = true;
    dispatch(loadUser());
  }

  if (auth.user) {
    setSentryUser(auth.user);
  }

  useWebSocket({
    channelName: 'events',
    privateChannel: false,
    listen: {
      event: '.version.update',
      callback: (event) => {
        if (event.env === 'admin') {
          dispatch(setVersionUpdate());
        }
      },
    },
  });

  if (auth.user && auth.user.contact.type === 'contact') {
    return <AuthClient />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route render={() => <Redirect to={appRoutes.DASHBOARD} />} path={appRoutes.STATUS_REPORT} />

          <AuthenticationRoute component={Dashboard} path={appRoutes.DASHBOARD} exact isAuth auth={auth} />
          <AuthenticationRoute component={Roles} path={appRoutes.ROLES} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Groups} path={appRoutes.GROUPS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Logs} path={appRoutes.LOGS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Clients} path={appRoutes.CLIENTS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Clients} path={appRoutes.ACCOUNT} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Customers} path={appRoutes.CUSTOMERS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Contacts} path={appRoutes.CONTACTS} isAuth auth={auth} />
          <AuthenticationRoute component={Requests} path={appRoutes.REQUESTS} isAuth auth={auth} />
          <AuthenticationRoute component={Forms} path={appRoutes.FORMS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Forms} path={appRoutes.SUBMISSIONS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Forms} path={appRoutes.SUBMIT_FORM} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Inbox} path={appRoutes.INBOX} isAuth auth={auth} />
          <AuthenticationRoute component={TimelogReport} path={appRoutes.TIME_LOG_REPORT} isAuth auth={auth} />
          <AuthenticationRoute component={ProductTypes} path={appRoutes.PRODUCT_TYPES} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute
            component={Projects}
            path={appRoutes.PROJECTS}
            isAuth
            auth={auth}
            type="regular_user"
            overflowScroll
          />
          <AuthenticationRoute component={Advertising} path={appRoutes.ADVERTISING} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Analytics} path={appRoutes.ANALYTICS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Content} path={appRoutes.CONTENT} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Integrations} path={appRoutes.INTEGRATIONS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Tasks} path={appRoutes.TASKS} isAuth auth={auth} />
          <AuthenticationRoute component={PersonalTasks} path={appRoutes.PERSONAL_TASKS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={CMS} path={appRoutes.CMS} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute
            component={Administration}
            path={appRoutes.ADMINISTRATION}
            isAuth
            auth={auth}
            type="regular_user"
          />
          <AuthenticationRoute
            component={ProjectTimeReport}
            path={appRoutes.PROJECT_TiME_REPORT}
            isAuth
            auth={auth}
            type="regular_user"
          />
          <AuthenticationRoute component={ActivityLog} path={appRoutes.ACTIVITY_LOG} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={GoogleOAuth} path={appRoutes.GOOGLE_OAUTH} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute component={Calendar} path={appRoutes.CALENDAR} isAuth auth={auth} type="regular_user" />
          <AuthenticationRoute
            component={CommentTemplate}
            path={appRoutes.COMMENT_TEMPLATES}
            isAuth
            auth={auth}
            type="regular_user"
          />
          <AuthenticationRoute component={FileViewer} path={`${appRoutes.FILE_VIEWER}/:uuid`} isAuth auth={auth} noLayout />

          <AuthenticationRoute component={Login} path={appRoutes.LOGIN} exact isAuth={false} auth={auth} />
          <AuthenticationRoute component={Register} path={appRoutes.REGISTER} exact isAuth={false} auth={auth} />
          <AuthenticationRoute component={ForgotPassword} path={appRoutes.FORGOT_PASSWORD} exact isAuth={false} auth={auth} />

          <AuthenticationRoute component={TermsOfServices} path={appRoutes.TERMS} exact isAuth={null} noLayout />
          <AuthenticationRoute component={PrivacyPolicy} path={appRoutes.PRIVACY} exact isAuth={null} noLayout />
          <AuthenticationRoute component={ResetPassword} path={appRoutes.RESET} exact isAuth={null} noLayout />

          <AuthenticationRoute component={NotFound404} path="*" isAuth={null} auth={auth} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routing;
