import { FC, RefObject } from 'react';
import { Popconfirm, Space } from 'antd';
import type { RichTextEditorHandle } from 'components/RichTextEditor';
import { getMentionUuidFromNode } from 'utils/string.utils';
import { UserResponse } from 'types';
import CommentReactions from '../CommentReactions';
import { UploadFileApi } from '../../types/upload-type';

interface CommentActionsBaseProps {
  comment: any;
  user: UserResponse;
  images: UploadFileApi[];
  type: 'tasks' | 'projects' | 'requests';
  loadingImages: number;
  editing: boolean;
  editorText: RefObject<RichTextEditorHandle>;
  onReplyTo: (replyToUuid: string, replyTo: any, creator?: any) => void;
  onEdit: (edit: boolean) => void;
}

export interface CommentActionsParentProps extends CommentActionsBaseProps {
  parentEl?: undefined;
  onAddReaction: (uuid: string, reaction: any) => void;
  onRemoveReaction: (uuid: string, reaction: any) => void;
  onUpdate: (uuid: string, values: any, uploads: UploadFileApi[], onFinish: () => void) => Promise<void>;
  onDelete: (uuid: string) => Promise<void>;
}

export interface CommentActionsChildrenProps extends CommentActionsBaseProps {
  parentEl: Record<string, any>;
  onAddReaction: (parentUuid: string, uuid: any, reaction: any) => void;
  onRemoveReaction: (parentUuid: string, uuid: any, reaction: any) => void;
  onUpdate: (uuid: string, parentUuid: string, values: any, uploads: UploadFileApi[], onFinish: () => void) => Promise<void>;
  onDelete: (uuid: string, parentUuid: string) => Promise<void>;
}

export type CommentActionsProps = CommentActionsParentProps | CommentActionsChildrenProps;

const CommentActions: FC<CommentActionsProps> = (props) => {
  const { comment, user, type, editing, editorText, onReplyTo, onEdit } = props;

  return (
    <Space size={12} style={{ marginTop: 12, lineHeight: 1 }}>
      <CommentReactions
        onAddReaction={(reaction: any) => {
          if (props.parentEl) {
            props.onAddReaction(props.parentEl.uuid, comment.uuid, reaction);
          } else {
            props.onAddReaction(comment.uuid, reaction);
          }
        }}
        onRemoveReaction={(reaction: any) => {
          if (props.parentEl) {
            props.onRemoveReaction(props.parentEl.uuid, comment.uuid, reaction);
          } else {
            props.onRemoveReaction(comment.uuid, reaction);
          }
        }}
        data={comment}
      />

      <button
        type="button"
        className="default-button"
        style={{ padding: 0 }}
        onClick={() => {
          const mentionReply = type !== 'tasks' || comment.creator.type !== 'contact';

          let targetReply = comment.creator.uuid !== user.uuid ? comment.creator : null;

          if (!targetReply && props.parentEl && props.parentEl.creator.uuid !== user.uuid) {
            targetReply = props.parentEl.creator;
          }

          onReplyTo((props.parentEl ?? comment).uuid, comment, mentionReply ? targetReply : null);
        }}
      >
        Reply
      </button>

      {editing && (
        <button
          type="button"
          className="primary default-button"
          style={{ padding: 0 }}
          onClick={() => {
            if (props.loadingImages) return;

            const newComment = editorText.current?.getValue();

            if (!newComment) return;

            const values = {
              text: newComment,
              related_users: getMentionUuidFromNode(newComment),
              uploads: props.images.filter((el) => el.response).map((el) => el.response?.uuid),
            };

            if (props.parentEl) {
              props.onUpdate(comment.uuid, props.parentEl.uuid, values, props.images, () => onEdit(false));
            } else {
              props.onUpdate(comment.uuid, values, props.images, () => onEdit(false));
            }
          }}
        >
          Save
        </button>
      )}

      {editing && (
        <button
          type="button"
          className="red default-button"
          style={{ padding: 0 }}
          onClick={() => {
            onEdit(false);
            editorText.current?.updateValue(comment.text ?? '');
          }}
        >
          Cancel
        </button>
      )}

      {user?.uuid === comment.creator.uuid && !editing && !comment.deleted_at ? (
        <>
          <button type="button" className="primary default-button" style={{ padding: 0 }} onClick={() => onEdit(true)}>
            Edit
          </button>

          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={async () => {
              if (props.parentEl) {
                await props.onDelete(comment.uuid, props.parentEl.uuid);
              } else {
                await props.onDelete(comment.uuid);
              }
            }}
            placement="bottom"
            title="Do you want to delete this comment?"
          >
            <button type="button" className="red default-button" style={{ padding: 0 }}>
              Delete
            </button>
          </Popconfirm>
        </>
      ) : undefined}
    </Space>
  );
};

export default CommentActions;
