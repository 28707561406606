import { Typography } from 'antd';
import { FC } from 'react';
import { convertSnakeCaseToTitleCase } from '../../utils/string.utils';

const { Text } = Typography;

interface RemovedTextLogProps {
  title: string;
}

const RemovedTextLog: FC<RemovedTextLogProps> = ({ title }) => {
  return <Text>Removed {convertSnakeCaseToTitleCase(title, false)}</Text>;
};

export default RemovedTextLog;
